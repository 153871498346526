import React, { createContext, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CryptoJS from "crypto-js";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

const encryptData = (data) => {
  return CryptoJS.AES.encrypt(data, SECRET_KEY).toString();
};

export const AuthProvider = ({ children }) => {
  const [successful, setSuccessful] = useState(false);
  const [err, setErr] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const login = async (email, password) => {
    setLoading(true);
    setErr("");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URI}/authentication/login`,
        { email, password }
      );

      const { userId, accessToken, refreshToken, roles } = response.data;

      if (response.status === 200) {
        localStorage.setItem("userId", encryptData(userId));
        localStorage.setItem("accessToken", encryptData(accessToken));
        localStorage.setItem("refreshToken", encryptData(refreshToken));
        localStorage.setItem("role", roles);

        if (roles.includes("Admin")) {
          window.location.href = "/students";
        } else if (roles.includes("User")) {
          window.location.href = "/courses";
        }
      } else {
        setErr("Login failed. Please check your credentials.");
      }
    } catch (error) {
      setErr(error.response?.data?.detail || "An unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };

  const signup = async (
    name,
    gender,
    guardianPhoneNumber,
    phoneNumber,
    address,
    academicLevel,
    school,
    governorate,
    area,
    email,
    password
  ) => {
    setLoading(true);
    setErr("");
    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_URI}/authentication/register`,
        {
          name,
          gender,
          guardianPhoneNumber,
          phoneNumber,
          address,
          academicLevel,
          school,
          governorate,
          area,
          email,
          password,
        }
      );
      setSuccessful(true);
      navigate("/login");
    } catch (error) {
      setErr(error.response?.data?.detail || "An unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };

  const logout = () => {
    localStorage.clear();
    navigate("/login");
  };

  return (
    <AuthContext.Provider
      value={{ login, signup, logout, err, loading, successful }}
    >
      {children}
    </AuthContext.Provider>
  );
};
