import React, { createContext, useState, useEffect } from "react";

// Create a Context for Dark Mode
const DarkModeContext = createContext();

const DarkModeProvider = ({ children }) => {
  // Initialize dark mode state from localStorage
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedMode = JSON.parse(localStorage.getItem("isDarkMode"));
    return savedMode !== null ? savedMode : false; // default to false if no saved mode
  });

  useEffect(() => {
    // Save dark mode state to localStorage
    localStorage.setItem("isDarkMode", JSON.stringify(isDarkMode));
  }, [isDarkMode]);

  const toggleDarkMode = () => {
    setIsDarkMode((prevMode) => !prevMode);
  };

  return (
    <DarkModeContext.Provider value={{ isDarkMode, toggleDarkMode }}>
      {children}
    </DarkModeContext.Provider>
  );
};

export { DarkModeProvider, DarkModeContext };
