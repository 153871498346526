import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaPhoneVolume } from "react-icons/fa6";
import {
  FaFacebook,
  FaTelegram,
  FaYoutube,
  FaWhatsapp,
  FaRegEye,
  FaRegEyeSlash,
} from "react-icons/fa";

import rightLogo from "../../assets/rightLogo.png";
import logo from "../../assets/logo.png";
import founder from "../../assets/founder.png";
import { useAuth } from "../../Context/AuthContext";
import ForgotPasswordModal from "../../Components/ForgotPasswordModal.jsx";
import Message from "../../Components/Message.jsx";
import Modal from "../../Components/Modal.jsx";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [forgetPasswordModalOpen, setForgetPasswordModalOpen] = useState(false);
  const { login, err, loading } = useAuth();

  //Error States
  const [isVisible, setIsVisible] = useState(false);
  const [animationClass, setAnimationClass] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handlePhoneModalOpen = () => {
    setModalOpen(true);
    setAnimationClass("slide-in");
  };

  const handlePhoneModalClose = () => {
    setModalOpen(false);
    setAnimationClass("slide-out");
  };

  // Email validation
  const validateEmail = (email) => {
    // Simple regex for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const newErrors = {};

    if (!email) {
      newErrors.email = "Email must not be empty";
    } else if (!validateEmail(email)) {
      newErrors.email = `"${email}" is not a valid email address`;
    }
    if (!password) {
      newErrors.password = ["Password must not be empty"];
    } else {
      // Detailed password validation
      const passwordErrors = [];
      if (password.length < 8)
        passwordErrors.push("Password must be at least 8 characters long");
      if (!/[A-Z]/.test(password))
        passwordErrors.push(
          "Password must contain at least 1 uppercase letter"
        );
      if (!/[a-z]/.test(password))
        passwordErrors.push(
          "Password must contain at least 1 lowercase letter"
        );
      if (!/\d/.test(password))
        passwordErrors.push("Password must contain at least 1 number");
      if (passwordErrors.length > 0) {
        newErrors.password = passwordErrors;
      }
    }

    await login(email, password);
  };

  const hideMessage = useCallback(() => {
    setAnimationClass("slide-out");
    setTimeout(() => setIsVisible(false), 500); // Match this timeout with the animation duration
  }, []);

  useEffect(() => {
    if (err) {
      setIsVisible(true);
      setAnimationClass("slide-in");
      const timer = setTimeout(hideMessage, 5000); // Hide the message after 5 seconds

      return () => clearTimeout(timer);
    } else {
      setIsVisible(false); // Hide the message if there's no error
    }
  }, [err, hideMessage]);

  //Forget Password Modal
  const handleForgotPassword = () => {
    setForgetPasswordModalOpen(true);
  };

  const closeModal = () => {
    setForgetPasswordModalOpen(false);
  };
  //Forget Password Modal

  return (
    <div className="lg:bg-customBlue flex items-center justify-center min-h-screen">
      {/* Container to align left and right side equally */}
      <div className="flex flex-col lg:flex-row w-full max-w-lg lg:max-w-[1500px] lg:h-[800px]">
        {/* LEFT SIDE */}
        <div className="bg-white lg:flex-1 lg:flex lg:flex-col items-center justify-center p-[40px] border-1 border-black hidden rounded-tl-[63px] rounded-bl-[63px]">
          <div className="flex flex-col items-center">
            {/* logo */}
            <Link to="/landing">
              <div className="flex justify-center h-[350px] w-[400px] pl-[40px] pt-[40px]">
                <img src={rightLogo} alt="" />
              </div>
            </Link>
          </div>
          <div className="flex justify-center p-[50px]">
            <ul className="flex items-center justify-center">
              <li className="p-3">
                <FaPhoneVolume
                  onClick={handlePhoneModalOpen}
                  className="hover:scale-125 duration-300 cursor-pointer w-[30px] h-[37px] md:w-[35px] md:h-[42px] lg:w-[40px] lg:h-[48px] text-[#1DB954]"
                />
                <Modal
                  isOpen={isModalOpen}
                  onClose={handlePhoneModalClose}
                  animationClass={animationClass}
                  title="Give Us A Call"
                  message="01559235216"
                />
              </li>
              <li className="p-3">
                <a
                  href="https://www.facebook.com/youssef.sherif.math.instructor?mibextid=ZbWKwL"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebook className="hover:scale-125 duration-300 cursor-pointer w-[38px] h-[38px] md:w-[42px] md:h-[42px] lg:w-[48px] lg:h-[48px] text-[#4267B2]" />
                </a>
              </li>
              <li className="p-3">
                <a
                  href="https://T.me/eduhighteam"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTelegram className="hover:scale-125 duration-300 cursor-pointer w-[38px] h-[38px] md:w-[42px] md:h-[42px] lg:w-[48px] lg:h-[48px] text-[#24A1DE]" />
                </a>
              </li>
              <li className="p-3">
                <a
                  href="https://youtube.com/@youssefsherif28?si=HLYhtGyg8uvxAYIa"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaYoutube className="hover:scale-125 duration-300 cursor-pointer w-[38px] h-[38px] md:w-[42px] md:h-[42px] lg:w-[48px] lg:h-[48px] text-[#FF0000]" />
                </a>
              </li>
              <li className="p-3">
                <a
                  href="http://wa.link/o3mbr0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaWhatsapp className="hover:scale-125 duration-300 cursor-pointer w-[38px] h-[38px] md:w-[42px] md:h-[42px] lg:w-[48px] lg:h-[48px] text-[#25D366]" />
                </a>
              </li>
            </ul>
          </div>

          {/* founder logo */}
          <div className="flex justify-center items-center gap-5 pb-6">
            <h3 className="text-center pb-1 text-[#06315B] font-bold">
              Founded By
            </h3>
            <img src={founder} alt="founder" />
          </div>
        </div>

        {/* RIGHT SIDE */}
        {/* Socials */}
        <div className="flex-1 flex flex-col items-center lg:bg-customRed justify-center p-[20px] lg:p-[40px] rounded-[32px] lg:rounded-tl-[0px] lg:rounded-bl-[0px]">
          <div className="flex justify-center p-5 lg:hidden">
            <ul className="flex items-center justify-center">
              <li className="p-3">
                <FaPhoneVolume className="hover:scale-125 duration-300 cursor-pointer w-[30px] h-[37px] md:w-[35px] md:h-[42px] lg:w-[40px] lg:h-[48px] text-[#1DB954]" />
              </li>
              <li className="p-3">
                <a
                  href="https://www.facebook.com/youssef.sherif.math.instructor?mibextid=ZbWKwL"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebook className="hover:scale-125 duration-300 cursor-pointer w-[38px] h-[38px] md:w-[42px] md:h-[42px] lg:w-[48px] lg:h-[48px] text-[#4267B2]" />
                </a>
              </li>
              <li className="p-3">
                <a
                  href="https://T.me/eduhighteam"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTelegram className="hover:scale-125 duration-300 cursor-pointer w-[38px] h-[38px] md:w-[42px] md:h-[42px] lg:w-[48px] lg:h-[48px] text-[#24A1DE]" />
                </a>
              </li>
              <li className="p-3">
                <a
                  href="https://youtube.com/@youssefsherif28?si=HLYhtGyg8uvxAYIa"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaYoutube className="hover:scale-125 duration-300 cursor-pointer w-[38px] h-[38px] md:w-[42px] md:h-[42px] lg:w-[48px] lg:h-[48px] text-[#FF0000]" />
                </a>
              </li>
              <li className="p-3">
                <a
                  href="https://wa.link/o3mbr0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaWhatsapp className="hover:scale-125 duration-300 cursor-pointer w-[38px] h-[38px] md:w-[42px] md:h-[42px] lg:w-[48px] lg:h-[48px] text-[#25D366]" />
                </a>
              </li>
            </ul>
          </div>

          <div className="lg:hidden flex flex-col items-center justify-center pb-6 ">
            {/* logo */}
            <Link to="/landing">
              <div className="flex justify-center">
                <img src={logo} alt="" />
              </div>
            </Link>
          </div>
          <div className="flex flex-col items-center justify-center bg-white max-w-[700px] h-[500px] px-[20px] lg:px-[40px] rounded-[32px] lg:border-0 border-2 border-customRed">
            <div className="text-4xl pt-6 font-bold text-center text-neutral-800">
              Login
            </div>
            <div className="self-stretch mt-2 text-base leading-5 text-center text-neutral-700">
              Welcome back to Eduhigh. Sign in to catch back up.
            </div>
            <div className="flex flex-col items-center justify-center pt-6 pb-2">
              {/* ALERT POP IN */}
              {
                <Message
                  isVisible={isVisible}
                  animationClass={animationClass}
                />
              }
              {/* ALERT POP IN */}
              <div className="w-full flex flex-col items-center">
                {/* Center content within the div */}
                <div className="w-full flex flex-col items-center space-y-2">
                  {/* Email Input */}
                  <input
                    type="text"
                    id="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="items-center px-3 py-4 mt-2.5 text-[14px] max-w-full bg-white rounded-xl border border-solid border-black border-opacity-20 w-[314px]"
                    required
                  />
                  {/* Password Input */}
                  <div className="relative">
                    <input
                      type={passwordVisible ? "text" : "password"}
                      id="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="px-3 py-4 mt-2.5 text-[14px] max-w-full bg-white rounded-xl border border-solid border-black border-opacity-20 w-[314px]"
                    />
                    <button
                      type="button"
                      onClick={togglePasswordVisibility}
                      className="absolute right-5 top-7 text-gray-500 lg:right-[20px]"
                    >
                      {passwordVisible ? (
                        <FaRegEye size={20} />
                      ) : (
                        <FaRegEyeSlash size={20} />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center">
              <button
                onClick={handleLogin}
                className="flex w-[321px] justify-center items-center px-16 pt-5 pb-5 mt-2 max-w-full text-2xl font-bold leading-tight text-center text-white bg-customRed rounded-2xl hover:scale-105 duration-300"
              >
                {loading ? (
                  <>
                    <svg
                      className="animate-spin h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                    >
                      <circle
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      />
                      <path d="M4 12a8 8 0 018-8v8H4z" fill="currentColor" />
                    </svg>
                    <span className="ml-2">Logging in</span>{" "}
                  </>
                ) : (
                  "Login"
                )}
              </button>
            </div>
            <div className="mt-3.5 pb-3 text-sm leading-tight text-center text-neutral-700">
              <button
                onClick={handleForgotPassword}
                className="underline text-blue-500"
              >
                Forgot Password?
              </button>
            </div>
            <div className="mt-3.5 pb-3 text-sm leading-tight text-center text-neutral-700">
              Create An Account?{" "}
              <Link to="/signup">
                <span className="underline cursor-pointer">Sign Up</span>
              </Link>
            </div>
          </div>
        </div>
        {/* founder */}
        <div className="flex justify-center items-center gap-5 pb-3 pt-8 lg:hidden">
          <h3 className="text-center pb-1 text-[#06315B] font-bold">
            Founded By
          </h3>
          <img src={founder} alt="" />
        </div>
      </div>
      <ForgotPasswordModal
        isOpen={forgetPasswordModalOpen}
        onClose={closeModal}
      />
    </div>
  );
}
