import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

//CONTEXTS
import { DarkModeProvider } from "./Context/DarkModeContext";
import { AuthProvider } from "./Context/AuthContext.js";

//LAYOUTS
import Layout from "./Components/Layout.jsx";
import LayoutAdmin from "./Components/Layout_Admin.jsx";

//UNPROTECTED Routes
import NoPage from "./pages/NoPage.jsx";
import LandingPage from "./pages/Auth/LandingPage.jsx";
import SignUpPage from "./pages/Auth/SignUpPage.jsx";
import LoginPage from "./pages/Auth/LoginPage.jsx";

//STUDENTS SECTION
import CoursesPage from "./pages/Student/CoursesPage.jsx";
import CourseContentPage from "./pages/Student/Content/CourseContentPage.jsx";
import StudentVideos from "./pages/Student/Content/videos/StudentVideos.jsx";
import StudentMaterials from "./pages/Student/Content/materials/StudentMaterials.jsx";
import StudentAssignments from "./pages/Student/Content/assignments/StudentAssignments.jsx";
import StudentQuizzes from "./pages/Student/Content/quizzes/StudentQuizzes.jsx";
import LiveRequestsPage from "./pages/Student/LiveRequestsPage.jsx";
import EditProfilePage from "./pages/Student/EditProfilePage.jsx";
import EditBasicInfoPage from "./pages/Student/EditProfilePage/EditBasicInfoPage.jsx";
import EditPasswordPage from "./pages/Student/EditProfilePage/EditPasswordPage.jsx";
import WalletPage from "./pages/Student/WalletPage.jsx";

//ADMIN SECTION
import AdminStudents from "./pages/Admin/AdminStudents.jsx";
import UserInfoPage from "./pages/Admin/UserInfoPage.jsx";
import AdminCourses from "./pages/Admin/AdminCourses.jsx";
import AdminCourseContentPage from "./pages/Admin/Content/AdminCourseContentPage.jsx";
import AdminVideos from "./pages/Admin/Content/videos/AdminVideos.jsx";
import AdminMaterials from "./pages/Admin/Content/materials/AdminMaterials.jsx";
import AdminQuizzes from "./pages/Admin/Content/quizzes/AdminQuizzes.jsx";
import AdminAssignments from "./pages/Admin/Content/assignments/AdminAssignments.jsx";
import AdminLiveRequest from "./pages/Admin/AdminLiveRequest.jsx";
import Sessions from "./pages/Admin/AdminLiveRequest/Sessions.jsx";
import SessionRequests from "./pages/Admin/AdminLiveRequest/SessionRequests.jsx";
import InsideSession from "./pages/Admin/AdminLiveRequest/InsideSession.jsx";
import AdminStore from "./pages/Admin/AdminStore.jsx";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const checkAuth = () => {
      const token = localStorage.getItem("accessToken");
      const refreshToken = localStorage.getItem("refreshToken");
      const role = localStorage.getItem("role");

      if (!token || !refreshToken) {
        localStorage.clear();
        setIsAuthenticated(false);
      } else {
        setIsAuthenticated(role);
      }
    };

    checkAuth();

    // Add a listener to update authentication status when localStorage changes
    window.addEventListener("storage", checkAuth);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("storage", checkAuth);
    };
  }, []);

  if (isAuthenticated === null) return null;

  return (
    <Router>
      <DarkModeProvider>
        <AuthProvider>
          <Routes>
            {/* Public routes */}
            <Route path="/landing" element={<LandingPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignUpPage />} />

            {/* Conditional route handling */}
            <Route
              path="/"
              element={
                !isAuthenticated ? (
                  <Navigate to="/landing" />
                ) : isAuthenticated === "User" ? (
                  <Navigate to="/courses" />
                ) : isAuthenticated === "Admin" ? (
                  <Navigate to="/students" />
                ) : null
              }
            />

            {/* Protected routes with Layout for Users */}
            {isAuthenticated === "User" && (
              <Route path="/" element={<Layout />}>
                <Route path="/courses" element={<CoursesPage />} />
                <Route path="/courses/:id" element={<CourseContentPage />} />
                <Route path="/courses/:id/videos" element={<StudentVideos />} />
                <Route
                  path="/courses/:id/materials"
                  element={<StudentMaterials />}
                />
                <Route
                  path="/courses/:id/quizzes"
                  element={<StudentQuizzes />}
                />
                <Route
                  path="/courses/:id/assignments"
                  element={<StudentAssignments />}
                />
                <Route path="live-sessions" element={<LiveRequestsPage />} />

                {/* Updated profile editing routes */}
                <Route path="edit-profile" element={<EditProfilePage />} />
                <Route
                  path="edit-profile/basic-info"
                  element={<EditBasicInfoPage />}
                />
                <Route
                  path="edit-profile/password"
                  element={<EditPasswordPage />}
                />

                <Route path="wallet" element={<WalletPage />} />
              </Route>
            )}

            {/* Protected routes with LayoutAdmin for Admins */}
            {isAuthenticated === "Admin" && (
              <Route path="/" element={<LayoutAdmin />}>
                <Route path="/students" element={<AdminStudents />} />
                <Route
                  path="/students/user/:userId"
                  element={<UserInfoPage />}
                />
                <Route path="/courses" element={<AdminCourses />} />
                <Route
                  path="courses/:courseId"
                  element={<AdminCourseContentPage />}
                />
                <Route
                  path="courses/:courseId/videos"
                  element={<AdminVideos />}
                />
                <Route
                  path="courses/:courseId/materials"
                  element={<AdminMaterials />}
                />
                <Route
                  path="courses/:courseId/quizzes"
                  element={<AdminQuizzes />}
                />
                <Route
                  path="courses/:courseId/assignments"
                  element={<AdminAssignments />}
                />
                {/* Updated live requests routes */}
                <Route path="live-sessions" element={<AdminLiveRequest />} />
                <Route path="live-sessions/sessions" element={<Sessions />} />
                <Route path="sessions/:sessionId" element={<InsideSession />} />
                <Route
                  path="live-sessions/sessions-requests"
                  element={<SessionRequests />}
                />

                <Route path="store" element={<AdminStore />} />
              </Route>
            )}

            {/* Catch-all route */}
            <Route path="*" element={<NoPage />} />
          </Routes>
        </AuthProvider>
      </DarkModeProvider>
    </Router>
  );
}

export default App;
