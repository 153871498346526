import { Link } from "react-router-dom";
import { useContext } from "react";
import { RiCalendarScheduleLine } from "react-icons/ri";
import { BsCalendarPlus } from "react-icons/bs";

import { DarkModeContext } from "../../Context/DarkModeContext.jsx";

export default function ProfileSettings() {
  const { isDarkMode } = useContext(DarkModeContext);

  return (
    <div
      className={`${
        isDarkMode ? "bg-[#1f2123] text-black" : "bg-white text-black"
      } p-8 pt-[80px] lg:pt-0 flex items-center justify-center min-h-screen`}
    >
      <div className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-8">
        <Link to="/live-sessions/sessions" className="text-blue-600">
          <div
            className={`cursor-pointer ${
              isDarkMode
                ? "border-[#2c2f31] border-2 text-white hover:border-white"
                : "bg-customBlue text-white hover:bg-transparent hover:text-customBlue border-[3px] border-customBlue"
            } rounded-[25px] flex flex-col items-center justify-center shadow-2xl p-6 w-full md:w-72 h-80 text-center hover:scale-105 ease-in-out duration-300`}
          >
            <h2 className="text-xl font-semibold">Sessions</h2>
            <RiCalendarScheduleLine size={120} className="mt-[55px]" />
          </div>
        </Link>
        <Link to="/live-sessions/sessions-requests" className="text-blue-600">
          <div
            className={`cursor-pointer ${
              isDarkMode
                ? "border-[#2c2f31] border-2 text-white hover:border-white"
                : "bg-customBlue text-white hover:bg-transparent hover:text-customBlue border-[3px] border-customBlue"
            } rounded-[25px] flex flex-col items-center justify-center shadow-2xl p-6 w-full md:w-72 h-80 text-center hover:scale-105 ease-in-out duration-300`}
          >
            <h2 className="text-xl font-semibold">Session Requests</h2>
            <BsCalendarPlus size={120} className="mt-[55px]" />
          </div>
        </Link>
      </div>
    </div>
  );
}
