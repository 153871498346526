import { IoIosCloseCircleOutline } from "react-icons/io";
import { LuPhoneCall } from "react-icons/lu";

const Modal = ({ isOpen, onClose, title, message, animationClass }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
      <div
        className={`${animationClass} relative flex flex-col bg-white p-6 rounded-[35px] shadow-lg w-80`}
      >
        {/* Close icon */}
        <IoIosCloseCircleOutline
          onClick={onClose}
          className="absolute top-4 right-4 text-2xl cursor-pointer text-gray-500 hover:text-red-500"
        />

        {/* Title and message */}
        <h2 className="text-2xl font-bold text-center mt-4 mb-4">{title}</h2>
        <p className="text-normal text-center">{message}</p>

        {/* Phone icon container */}
        <div className="absolute top-[-30px] left-1/2 transform -translate-x-1/2 flex justify-center items-center">
          <div className="bg-white w-[80px] h-[60px] shadow-md rounded-full border border-gray-300 flex justify-center items-center">
            <LuPhoneCall size={40} className="text-[#1DB954] animate-pulse" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
