import React, { useContext, useState } from "react";
import { FaCheckCircle, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { IoArrowBackCircle } from "react-icons/io5";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";
import { DarkModeContext } from "../../../Context/DarkModeContext";
import axiosInstance from "../../../Context/axiosInstance.js";

function EditPasswordPage() {
  const { isDarkMode } = useContext(DarkModeContext);
  const navigate = useNavigate();

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordVisibleCurrent, setPasswordVisibleCurrent] = useState(false);
  const [passwordVisibleNew, setPasswordVisibleNew] = useState(false);
  const [passwordVisibleConfirm, setPasswordVisibleConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isPasswordMatching, setIsPasswordMatching] = useState(null);
  const [isNewPasswordValid, setIsNewPasswordValid] = useState(null);

  const [touchedNewPassword, setTouchedNewPassword] = useState(false);
  const [touchedConfirmPassword, setTouchedConfirmPassword] = useState(false);

  const userId = localStorage.getItem("userId");

  const decryptData = (encryptedData) => {
    const bytes = CryptoJS.AES.decrypt(
      encryptedData,
      process.env.REACT_APP_SECRET_KEY
    );
    return bytes.toString(CryptoJS.enc.Utf8);
  };

  const isValidPassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/;
    return passwordRegex.test(password);
  };

  const isPasswordMatch = (password, confirmPassword) => {
    return password === confirmPassword;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    setSuccessMessage("");

    // Validate passwords
    if (!isValidPassword(newPassword)) {
      setError(
        "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, and one number."
      );
      setLoading(false);
      return;
    }

    if (!isPasswordMatch(newPassword, confirmPassword)) {
      setError("New password and confirmation password do not match.");
      setLoading(false);
      return;
    }

    try {
      await axiosInstance.put(`/users/${decryptData(userId)}/password`, {
        currentPassword,
        newPassword,
      });
      setSuccessMessage("Password successfully updated!");
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (err) {
      if (err.response) {
        const { data } = err.response;

        if (data.errors && Array.isArray(data.errors)) {
          const errorMessages = data.errors.map((error, index) => (
            <div key={index}>{error.reason}</div>
          ));
          setError(
            errorMessages ||
              "Failed to update profile or password. Please try again."
          );
        } else {
          setError(
            data?.detail ||
              "Failed to update profile or password. Please try again."
          );
        }
      } else {
        setError("Network error. Please check your connection.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleNewPasswordChange = (e) => {
    const value = e.target.value;
    setNewPassword(value);
    setTouchedNewPassword(true);

    // Validate the new password
    if (value.trim() === "") {
      setIsNewPasswordValid(null); // Don't validate if empty
    } else {
      setIsNewPasswordValid(isValidPassword(value));
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
    setTouchedConfirmPassword(true);

    // Validate the confirm password
    if (value.trim() === "") {
      setIsPasswordMatching(null); // Don't validate if empty
    } else {
      setIsPasswordMatching(isPasswordMatch(newPassword, value));
    }
  };

  return (
    <div
      className={`${
        isDarkMode ? "bg-[#1e1e1e] text-white" : "bg-customBlue text-black"
      } flex items-center justify-center min-h-screen p-6 pt-[80px] lg:p-8 lg:pr-[50px] pb-[50px] lg:pb-[0]`}
    >
      <div className="bg-white text-black p-8 rounded-lg shadow-xl w-full max-w-4xl">
        <div
          onClick={() => navigate(-1)}
          className="cursor-pointer flex items-center mb-4 text-blue-500"
        >
          <IoArrowBackCircle className="mr-1" size={20} />
          <button>Back</button>
        </div>
        <h2 className="text-2xl font-bold mb-4">Change Password</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid grid-cols-1 gap-4">
            <div className="relative transition-opacity duration-300 ease-in-out">
              <label
                htmlFor="currentPassword"
                className="block text-sm font-medium"
              >
                Current Password
              </label>
              <div className="flex items-center mt-1">
                <input
                  type={passwordVisibleCurrent ? "text" : "password"}
                  id="currentPassword"
                  name="currentPassword"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  className="p-2 w-full border rounded pr-10"
                />
                <button
                  type="button"
                  onClick={() =>
                    setPasswordVisibleCurrent(!passwordVisibleCurrent)
                  }
                  className="absolute right-2 text-gray-500"
                  aria-label="Toggle password visibility"
                >
                  {passwordVisibleCurrent ? (
                    <FaRegEye size={20} />
                  ) : (
                    <FaRegEyeSlash size={20} />
                  )}
                </button>
              </div>
            </div>

            <div className="relative transition-opacity duration-300 ease-in-out">
              <div className="flex gap-1 items-center">
                <label
                  htmlFor="newPassword"
                  className="block text-sm font-medium"
                >
                  New Password
                </label>
              </div>
              <div className="flex items-center mt-1">
                <input
                  type={passwordVisibleNew ? "text" : "password"}
                  id="newPassword"
                  name="newPassword"
                  value={newPassword}
                  onChange={handleNewPasswordChange}
                  className="p-2 w-full border rounded pr-10"
                />
                <button
                  type="button"
                  onClick={() => setPasswordVisibleNew(!passwordVisibleNew)}
                  className={`${
                    isNewPasswordValid ? "right-9" : "right-2"
                  } absolute text-gray-500`}
                  aria-label="Toggle password visibility"
                >
                  {passwordVisibleNew ? (
                    <FaRegEye size={20} />
                  ) : (
                    <FaRegEyeSlash size={20} />
                  )}
                </button>
                {isNewPasswordValid === true && (
                  <FaCheckCircle className="ml-2 text-green-500" />
                )}
              </div>
              {isNewPasswordValid === false && touchedNewPassword && (
                <p className="pt-2 text-red-500 text-sm">
                  Password must contain at least 8 characters, one uppercase
                  letter, one lowercase letter, and one number.
                </p>
              )}
            </div>

            <div className="relative transition-opacity duration-300 ease-in-out">
              <div className="flex items-center gap-1">
                <label
                  htmlFor="confirmPassword"
                  className="block text-sm font-medium"
                >
                  Confirm New Password
                </label>
              </div>
              <div className="flex items-center mt-1">
                <input
                  type={passwordVisibleConfirm ? "text" : "password"}
                  id="confirmPassword"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  className="p-2 w-full border rounded"
                />
                <button
                  type="button"
                  onClick={() =>
                    setPasswordVisibleConfirm(!passwordVisibleConfirm)
                  }
                  className={`${
                    isPasswordMatching ? " right-9" : " right-2"
                  } absolute text-gray-500`}
                  aria-label="Toggle password visibility"
                >
                  {passwordVisibleConfirm ? (
                    <FaRegEye size={20} />
                  ) : (
                    <FaRegEyeSlash size={20} />
                  )}
                </button>
                {isPasswordMatching === true && (
                  <FaCheckCircle className="ml-2 text-green-500" />
                )}
              </div>
              {isPasswordMatching === false && touchedConfirmPassword && (
                <p className="pt-2 text-red-500 text-sm">
                  Passwords do not match.
                </p>
              )}
            </div>
          </div>
          {error && (
            <div className="text-red-500">
              <p>
                {typeof error === "string"
                  ? error
                  : Object.keys(error).length > 0 && (
                      <div className="text-red-500">
                        <p>{error}</p>
                      </div>
                    )}
              </p>
            </div>
          )}
          {successMessage && (
            <div className="text-green-500">{successMessage}</div>
          )}
          <div className="flex justify-center">
            <button
              type="submit"
              disabled={loading}
              className={`w-[210px] max-w-xs p-2 mt-4 rounded-[20px] ${
                loading
                  ? "bg-gray-300"
                  : "bg-blue-600 text-white hover:bg-blue-700"
              }`}
            >
              {loading ? "Changing..." : "Change Password"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditPasswordPage;
