import React, { useRef } from "react";
import { IoMdCloseCircleOutline } from "react-icons/io";

export const VideoModal = ({ isOpen, videoUrl, onClose, link }) => {
  const videoRef = useRef(null);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center z-50 transition-opacity duration-300 ease-in-out">
      {/* <div className="sm:w-[350px] md:w-[550px] bg-white p-1 rounded-lg shadow-xl max-w-2xl md&lg:w-full relative transform transition-transform duration-300 ease-in-out"> */}
      <div className="w-[350px] sm:max-w-screen md:w-[550px] lg:w-[700px] bg-white p-1 rounded-lg shadow-xl relative transform transition-transform duration-300 ease-in-out">
        <div className="flex flex-col gap-3 items-center">
          <IoMdCloseCircleOutline
            size={30}
            className="cursor-pointer text-red-600 hover:text-red-800 text-3xl focus:outline-none"
            onClick={onClose}
          />
          <iframe
            ref={videoRef}
            className="w-full h-[400px] rounded-md"
            src={videoUrl}
            title="Video Player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        {link && (
          <div className="p-4 text-center bg-gray-100">
            <h2 className="text-blue-900 capitalize">Video Link</h2>
            <div className="scrollable-div text-center p-2 border-2 border-teal-500 rounded-full bg-white shadow-md overflow-auto max-h-24">
              <p className="text-blue-800 break-words">{link}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
