import React, { useState, useEffect, useCallback, useRef } from "react";
import { FaTimes, FaRegEye, FaRegEyeSlash } from "react-icons/fa";

import "../index.css";
import axiosInstance from "../Context/axiosInstance.js";

const ForgotPasswordModal = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]); // Changed to array
  const [success, setSuccess] = useState(null);
  const [animationClass, setAnimationClass] = useState("");
  const inputRefs = useRef([]);

  const handleChange = (index, e) => {
    const newToken = [...token];
    newToken[index] = e.target.value.slice(0, 1); // Only allow one character
    setToken(newToken.join(""));

    // Move to the next input if the current one is filled
    if (e.target.value.length === 1 && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleClose = useCallback(() => {
    setAnimationClass("slide-out");
    setTimeout(() => {
      onClose();
      // Reset state variables
      setEmail("");
      setToken("");
      setNewPassword("");
      setShowPassword(false);
      setStep(1);
      setErrors([]); // Reset errors
      setSuccess(null); // Reset success message
      setLoading(false); // Reset loading state
      setAnimationClass("");
    }, 300);
  }, [onClose]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        handleClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleClose]);

  useEffect(() => {
    if (isOpen) {
      setAnimationClass("slide-in");
    }
  }, [isOpen]);

  const handleRequestToken = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrors([]); // Reset errors
    setSuccess(null);

    try {
      const response = await axiosInstance.post(
        "/authentication/generate-forgot-password-token",
        { email }
      );
      setSuccess(
        response.data.message || "Password reset link sent successfully!"
      );
      setStep(2);
    } catch (error) {
      const errorMessages = error.response?.data?.errors || [
        error.response?.data?.message || "An error occurred. Please try again.",
      ];
      setErrors(errorMessages);
    } finally {
      setLoading(false);
    }
  };

  const handleCheckToken = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrors([]);
    setSuccess(null);

    try {
      const response = await axiosInstance.get(
        "/authentication/check-forgot-password-token-validity",
        { params: { email, token } }
      );
      if (response.status === 200) {
        setStep(3);
      } else {
        setErrors(["Invalid token. Please try again."]);
      }
    } catch (error) {
      const errorMsg = error.response?.data;
      const message = errorMsg
        ? `${errorMsg.detail || "An error occurred."}`
        : "An error occurred. Please try again.";
      setErrors((prev) => [...prev, message]);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrors([]);
    setSuccess(null);

    try {
      const response = await axiosInstance.post(
        "/authentication/change-password-by-token",
        { email, token, newPassword }
      );
      setSuccess(response.data.message || "Password changed successfully!");
      // Close the modal after 3 seconds
      setTimeout(() => {
        handleClose();
      }, 3000);
    } catch (error) {
      const errorMessages = error.response?.data?.errors || [
        error.response?.data?.message || "An error occurred. Please try again.",
      ];
      setErrors(errorMessages);
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen && animationClass !== "slide-in") return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div
        className={`${animationClass} bg-white p-6 rounded-lg shadow-lg max-w-sm w-full relative`}
      >
        <button
          onClick={handleClose}
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
        >
          <FaTimes className="text-customRed" size={20} />
        </button>
        <h2 className="text-xl font-bold mb-4 text-center">Forgot Password</h2>
        <p className="text-base mb-4 text-center">
          If you have an email, a token will be sent to it. Otherwise, please
          sign up first.
        </p>

        {step === 1 && (
          <form onSubmit={handleRequestToken}>
            <div className="relative mb-3">
              <input
                type="email"
                id="email"
                placeholder=" "
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="floating-input w-full px-3 py-2 mt-2.5 text-[14px] max-w-full bg-white rounded-xl border border-solid border-black border-opacity-20"
                required
              />
              <label htmlFor="email" className="floating-label">
                Enter your email
              </label>
            </div>
            {errors.length > 0 && (
              <div className="text-red-500 pb-2">
                {errors.map((error, index) => (
                  <p key={index}>
                    {typeof error === "string"
                      ? error
                      : error && typeof error === "object"
                      ? `${error.reason}`
                      : "An unknown error occurred."}
                  </p>
                ))}
              </div>
            )}
            {success && (
              <p className="text-green-500 text-center pb-2">{success}</p>
            )}
            <button
              type="submit"
              className="w-full py-2 bg-customRed text-white rounded-[4px]"
              disabled={loading}
            >
              {loading ? "Sending..." : "Send Reset Link"}
            </button>
          </form>
        )}

        {step === 2 && (
          <form onSubmit={handleCheckToken}>
            <div className="flex space-x-2 mb-3">
              {Array.from({ length: 6 }).map((_, index) => (
                <div key={index} className="relative">
                  <input
                    type="text"
                    id={`token-${index}`}
                    placeholder=" "
                    value={token[index] || ""}
                    onChange={(e) => handleChange(index, e)}
                    ref={(el) => (inputRefs.current[index] = el)}
                    className="floating-input w-full px-3 py-2 text-[14px] bg-white rounded-xl border border-solid border-black border-opacity-20 text-center"
                    maxLength={1}
                    required
                  />
                </div>
              ))}
            </div>
            {errors.length > 0 && (
              <div className="text-red-500 pb-2">
                {errors.map((error, index) => (
                  <p key={index}>
                    {typeof error === "string"
                      ? error
                      : error && typeof error === "object"
                      ? `${error.reason}`
                      : "An unknown error occurred."}
                  </p>
                ))}
              </div>
            )}
            {success && (
              <p className="text-green-500 text-center pb-2">{success}</p>
            )}
            <button
              type="submit"
              className="w-full py-2 bg-customRed text-white rounded-[4px]"
              disabled={loading}
            >
              {loading ? "Checking token..." : "Check Token"}
            </button>
          </form>
        )}

        {step === 3 && (
          <form onSubmit={handleChangePassword}>
            <div className="relative mb-3">
              <input
                type={showPassword ? "text" : "password"}
                id="enterNewPassword"
                placeholder=" "
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="floating-input w-full px-3 py-2 mt-2.5 text-[14px] max-w-full bg-white rounded-xl border border-solid border-black border-opacity-20"
                required
              />
              <label htmlFor="enterNewPassword" className="floating-label">
                Enter new password
              </label>
              <button
                type="button"
                className="absolute right-4 top-1/2 transform -translate-y-[30%] text-gray-500"
                onClick={() => setShowPassword((prev) => !prev)}
              >
                {showPassword ? (
                  <FaRegEye size={20} />
                ) : (
                  <FaRegEyeSlash size={20} />
                )}
              </button>
            </div>
            {errors.length > 0 && (
              <div className="text-red-500 pb-2">
                {errors.map((error, index) => (
                  <p key={index}>
                    {typeof error === "string"
                      ? error
                      : error && typeof error === "object"
                      ? `${error.reason}`
                      : "An unknown error occurred."}
                  </p>
                ))}
              </div>
            )}
            {success && (
              <p className="text-green-500 text-center pb-2">{success}</p>
            )}
            <button
              type="submit"
              className="w-full py-2 bg-customRed text-white rounded-[4px]"
              disabled={loading}
            >
              {loading ? "Changing password..." : "Change Password"}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default ForgotPasswordModal;
