import { Link } from "react-router-dom";
import { FaPhoneVolume } from "react-icons/fa6";
import { FaFacebook, FaTelegram, FaYoutube, FaWhatsapp } from "react-icons/fa";
import { useState } from "react";

import "../../index.css";
import logo from "../../assets/landing_logo.png";
import founder from "../../assets/founder.png";
import Modal from "../../Components/Modal";

function LandingPage() {
  const [animationClass, setAnimationClass] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);

  const handlePhoneModalOpen = () => {
    setModalOpen(true);
    setAnimationClass("slide-in");
  };

  const handlePhoneModalClose = () => {
    setModalOpen(false);
    setAnimationClass("slide-out");
  };

  return (
    <div className="lg:bg-customBlue lg:h-screen lg:px-[120px] lg:py-[70px]">
      <div className="bg-white lg:max-h-[90vh] lg:py-[40px] lg:pb-[20px]">
        {/* socials */}
        <div>
          <ul className="flex lg:flex-col p-5 justify-center lg:w-[200px] lg:absolute lg:top-[80px]">
            <li className="p-3">
              <FaPhoneVolume
                onClick={handlePhoneModalOpen}
                className="hover:scale-125 duration-300 cursor-pointer w-[30px] h-[37px] md:w-[35px] md:h-[42px] lg:w-[40px] lg:h-[48px] text-[#1DB954]"
              />
              <Modal
                isOpen={isModalOpen}
                onClose={handlePhoneModalClose}
                animationClass={animationClass}
                title="Give Us A Call"
                message="01559235216"
              />
            </li>
            <li className="p-3">
              <a
                href="https://www.facebook.com/youssef.sherif.math.instructor?mibextid=ZbWKwL"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebook className="hover:scale-125 duration-300 cursor-pointer w-[38px] h-[38px] md:w-[42px] md:h-[42px] lg:w-[48px] lg:h-[48px] text-[#4267B2]" />
              </a>
            </li>
            <li className="p-3">
              <a
                href="https://T.me/eduhighteam"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTelegram className="hover:scale-125 duration-300 cursor-pointer w-[38px] h-[38px] md:w-[42px] md:h-[42px] lg:w-[48px] lg:h-[48px] text-[#24A1DE]" />
              </a>
            </li>
            <li className="p-3">
              <a
                href="https://youtube.com/@youssefsherif28?si=HLYhtGyg8uvxAYIa"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaYoutube className="hover:scale-125 duration-300 cursor-pointer w-[38px] h-[38px] md:w-[42px] md:h-[42px] lg:w-[48px] lg:h-[48px] text-[#FF0000]" />
              </a>
            </li>
            <li className="p-3">
              <a
                href="https://wa.link/o3mbr0"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaWhatsapp className="hover:scale-125 duration-300 cursor-pointer w-[38px] h-[38px] md:w-[42px] md:h-[42px] lg:w-[48px] lg:h-[48px] text-[#25D366]" />
              </a>
            </li>
          </ul>

          <div className="flex flex-col pt-[40px] items-center justify-center lg:pt-[40px] min-h-[80vh] max-h-[1000px]">
            {/* logo */}
            <div className="flex justify-center">
              <img
                className="w-[360px] h-[90px] lg:w-[800px] lg:h-[200px]"
                src={logo}
                alt=""
              />
            </div>

            {/* description */}
            <div className="items-center text-center p-10 font-semibold text-md lg:p-7 lg:text-lg lg:w-[700px]">
              <h2>
                This academy is for those fortunate enough to study mathematics.
                Our aim is to provide an adaptive learning environment that
                allows you to enjoy learning mathematics through the most
                effective teaching methods.
              </h2>
            </div>
            {/* buttons */}
            <div className="flex flex-col p-5 gap-3">
              <Link to="/signup">
                <button className="bg-customRed text-[18px] p-4 rounded-[15px] w-[250px] text-white font-[Karla] font-bold hover:scale-110 ease-in-out duration-300">
                  Sign Up
                </button>
              </Link>
              <Link to="/login">
                <button className="bg-customBlue text-[18px] p-4 rounded-[15px] w-[250px] text-white font-[Karla] font-bold hover:scale-110 ease-in-out duration-300">
                  Login
                </button>
              </Link>
            </div>
            {/* founder */}
            <div>
              <h3 className="text-center pb-1 text-[#06315B] font-bold">
                Founded By
              </h3>
              <img src={founder} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
