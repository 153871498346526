import { Link } from "react-router-dom";
import { DarkModeContext } from "../../Context/DarkModeContext";
import { useContext } from "react";

export default function ProfileSettings() {
  const { isDarkMode } = useContext(DarkModeContext);

  return (
    <div
      className={`${
        isDarkMode ? "bg-[#181818]" : "bg-customBlue"
      } flex items-center justify-center min-h-screen bg-cover bg-center`}
    >
      <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
        <Link to="/edit-profile/basic-info" className="text-blue-600">
          <div
            className={`cursor-pointer ${
              isDarkMode
                ? "border-white border-2 text-white"
                : "bg-white  text-black"
            } rounded-lg flex items-center justify-center shadow-lg p-6 w-full md:w-64 text-center hover:scale-105 ease-in-out duration-200`}
          >
            <h2 className="text-lg font-medium">Basic Information</h2>
          </div>
        </Link>
        <Link to="/edit-profile/password" className="text-blue-600">
          <div
            className={`cursor-pointer ${
              isDarkMode
                ? "border-white border-2 text-white"
                : "bg-white  text-black"
            } rounded-lg flex items-center justify-center shadow-lg p-6 w-full md:w-64 text-center hover:scale-105 ease-in-out duration-200`}
          >
            <h2 className="text-lg font-medium">Password</h2>
          </div>
        </Link>
      </div>
    </div>
  );
}
