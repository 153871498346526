import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

import "../../../index.css";
import { DarkModeContext } from "../../../Context/DarkModeContext.jsx";
import axiosInstance from "../../../Context/axiosInstance.js";

function Sessions() {
  const { isDarkMode } = useContext(DarkModeContext);
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  // Retrieve values from URL parameters
  const pageFromUrl = parseInt(params.get("page"), 10) || 1;
  const pageSizeFromUrl = parseInt(params.get("pageSize"), 10) || 20;

  const [page, setPage] = useState(pageFromUrl);
  const [pageSize, setPageSize] = useState(pageSizeFromUrl);
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(1);

  const [modalOpen, setModalOpen] = useState(false);
  const [fullDescription, setFullDescription] = useState("");
  const [closing, setClosing] = useState(false);

  // GET Request: Fetching Data
  const fetchSessions = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axiosInstance.get(
        `/sessions/requests?page=${page}&pageSize=${pageSize}`
      );

      setSessions(response.data.response);
      setTotalPages(Math.ceil(response.data.totalCount / pageSize));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Unauthorized error (token expired or invalid)
        // The Axios interceptor will handle the token refresh and retry.
      } else {
        setError(error.message || "An unexpected error occurred.");
      }
    } finally {
      setLoading(false);
    }
  }, [page, pageSize]);

  useEffect(() => {
    fetchSessions();
  }, [fetchSessions]);

  // Handlers for pagination
  const goToNextPage = () => {
    navigate(+1);
  };

  const goToPreviousPage = () => {
    navigate(-1);
  };

  const formattedPath = `${location.pathname
    .slice(1) // Remove leading slash
    .replace(/[_-]/g, " ") // Replace underscores or hyphens with spaces
    .replace(/\b\w/g, (char) => char.toUpperCase()) // Capitalize first letter of each word
    .replace(/\//g, " > ")}
        > `;

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const openModal = (description) => {
    setFullDescription(description);
    setModalOpen(true);
  };

  const closeModal = () => {
    setClosing(true);
    setTimeout(() => {
      setModalOpen(false);
      setClosing(false);
      setFullDescription("");
    }, 300);
  };

  return (
    <div
      className={`flex flex-col lg:flex-row lg:items-start ${
        isDarkMode ? "bg-[#292929] text-black" : "bg-white text-black"
      } p-4 lg:p-8 min-h-screen`}
    >
      <div
        className={`${
          isDarkMode ? "bg-[#1e1e1e] text-white" : "bg-white text-black"
        } p-4 lg:p-8 mt-[40px] lg:mt-0 rounded-lg shadow-xl flex-1`}
      >
        {/* PATH AND CONTROLS */}
        <div className="lg:flex w-full justify-between items-center mb-4 text-gray-700 text-sm capitalize">
          <div className="flex gap-1 items-center">
            <button
              onClick={goToPreviousPage}
              className="w-[24px] h-[20px] flex justify-center items-center bg-gray-300 rounded-[6px] disabled:opacity-50"
            >
              <IoIosArrowBack />
            </button>
            <button
              onClick={goToNextPage}
              className="w-[24px] h-[20px] flex justify-center items-center bg-gray-300 rounded-[6px] disabled:opacity-50"
            >
              <IoIosArrowForward />
            </button>

            <div
              className={`${
                isDarkMode ? "text-white" : "text-black"
              } text-sm capitalize px-2 py-4`}
            >
              <span>{formattedPath}</span>
            </div>
          </div>
        </div>

        <div className="max-w-[320px] md:max-w-[700px] lg:max-w-full overflow-x-auto pb-4">
          <table className="min-w-full divide-y divide-gray-200">
            <thead
              className={`${
                isDarkMode ? "bg-[#1e1e1e] text-white" : "bg-white text-black"
              }`}
            >
              <tr>
                <th className="px-4 py-2 text-center text-base font-medium text-gray-500 uppercase tracking-wider">
                  Student Name
                </th>
                <th className="px-4 py-2 text-center text-base font-medium text-gray-500 uppercase tracking-wider">
                  WhatsApp Number
                </th>
                <th className="px-4 py-2 text-center text-base font-medium text-gray-500 uppercase tracking-wider">
                  Description
                </th>
                <th className="px-4 py-2 text-center text-base font-medium text-gray-500 uppercase tracking-wider">
                  Created On
                </th>
              </tr>
            </thead>
            <tbody
              className={`${
                isDarkMode ? "text-white" : "text-black"
              } divide-y divide-gray-200`}
            >
              {sessions.length > 0 &&
                sessions.map((session) => (
                  <tr key={session.id}>
                    <td className="px-4 py-2 whitespace-nowrap text-base text-center">
                      {session.userName}
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap text-base text-center">
                      {session.userWhatsAppNumber}
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap text-base text-center">
                      {session.description.length > 50 ? (
                        <>
                          {session.description.slice(0, 50)}...
                          <button
                            onClick={() => openModal(session.description)}
                            className="ml-2 text-blue-500 underline"
                          >
                            View More
                          </button>
                        </>
                      ) : (
                        session.description
                      )}
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap text-base text-center text-[#2083D8]">
                      {new Date(session.createdOnUtc).toLocaleString("en-EG", {
                        timeZone: "Africa/Cairo",
                      })}
                    </td>
                  </tr>
                ))}
              {!sessions.length && !loading && (
                <tr className="h-[650px]">
                  <td
                    colSpan="7"
                    className={`${
                      isDarkMode ? "text-white" : "text-gray-500"
                    } text-center text-lg text-gray-500`}
                  >
                    No Sessions found
                  </td>
                </tr>
              )}
              {loading && (
                <tr>
                  <td
                    colSpan="6"
                    className={`${
                      isDarkMode ? "text-white" : "text-gray-500"
                    } px-2 py-5 text-center text-lg`}
                  >
                    <div className="flex justify-center items-center h-[650px]">
                      <div className="loader">
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {error && (
          <tr>
            <td
              colSpan="6"
              className={`${
                isDarkMode ? "text-white" : "text-gray-500"
              } px-2 py-5 text-center text-lg`}
            >
              No Sessions Available
            </td>
          </tr>
        )}
        {error && <p className="mt-4 text-lg text-red-600">{error.message}</p>}
        <div className="flex items-center justify-end py-4 text-black">
          <label
            htmlFor="pageSize"
            className={`${
              isDarkMode ? "text-white" : "text-gray-700"
            } mr-2 text-sm`}
          >
            Requests per page:
          </label>
          <select
            id="pageSize"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
              setPage(1);
            }}
            className="px-4 py-2 border border-gray-300 rounded-lg"
          >
            {[20, 30, 50].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
        <div className="mt-4 flex flex-col sm:flex-row justify-between items-center">
          <button
            onClick={() => handlePageChange(page - 1)}
            disabled={page <= 1}
            className={`px-4 py-2 bg-gray-300 text-black rounded-lg ${
              page <= 1 ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-400"
            }`}
          >
            Previous
          </button>
          <span className={"text-lg mt-2 sm:mt-0"}>
            Page {page} of {totalPages}
          </span>
          <button
            onClick={() => handlePageChange(page + 1)}
            disabled={page >= totalPages}
            className={`px-4 py-2 bg-gray-300 text-black rounded-lg ${
              page >= totalPages
                ? "opacity-50 cursor-not-allowed"
                : "hover:bg-gray-400"
            }`}
          >
            Next
          </button>
        </div>
      </div>
      {modalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div
            className={`${
              closing
                ? "modal-sessions-description-close"
                : "modal-sessions-description-open"
            } ${
              isDarkMode ? "bg-gray-700 text-white" : "bg-white text-black"
            } rounded-lg p-4 w-[90%] md:w-[600px]`}
          >
            <h2 className="text-lg text-center font-semibold mb-4 text-blue-500 underline">
              Full Description
            </h2>
            <p className="text-center">{fullDescription}</p>

            <div className="flex justify-center">
              <button
                onClick={closeModal}
                className=" mt-4 px-6 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-[20px]"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Sessions;
