import axios from "axios";
import CryptoJS from "crypto-js";

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

const encryptData = (data) => {
  return CryptoJS.AES.encrypt(data, SECRET_KEY).toString();
};

const decryptData = (data) => {
  const bytes = CryptoJS.AES.decrypt(data, SECRET_KEY);
  return bytes.toString(CryptoJS.enc.Utf8);
};

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URI,
  withCredentials: true,
});

let isRefreshing = false; // Flag to indicate if a refresh is in progress
let pendingRequests = []; // Array to hold pending requests

const onRefreshed = (accessToken) => {
  pendingRequests.forEach((callback) => callback(accessToken));
  pendingRequests = [];
};

const addPendingRequest = (callback) => {
  pendingRequests.push(callback);
};

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const encryptedAccessToken = localStorage.getItem("accessToken");
    if (encryptedAccessToken) {
      const accessToken = decryptData(encryptedAccessToken);
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Handle 401 errors
    if (error.response && error.response.status === 401) {
      if (isRefreshing) {
        // If a refresh is already in progress, add the request to the pending queue
        return new Promise((resolve) => {
          addPendingRequest((accessToken) => {
            originalRequest.headers.Authorization = `Bearer ${accessToken}`;
            resolve(axiosInstance(originalRequest));
          });
        });
      }

      isRefreshing = true; // Set refreshing flag
      const encryptedRefreshToken = localStorage.getItem("refreshToken");
      const encryptedAccessToken = localStorage.getItem("accessToken");
      const encryptedUserId = localStorage.getItem("userId");

      if (!encryptedRefreshToken || !encryptedAccessToken || !encryptedUserId) {
        localStorage.clear();
        window.location.href = "/login";
        return Promise.reject(new Error("Incomplete authentication data"));
      }

      try {
        const refreshToken = decryptData(encryptedRefreshToken);
        const accessToken = decryptData(encryptedAccessToken);
        const userId = decryptData(encryptedUserId);

        const response = await axiosInstance.post(
          "/authentication/refresh-access-token",
          { userId, accessToken, refreshToken }
        );

        const { accessToken: newAccessToken, refreshToken: newRefreshToken } =
          response.data;

        const encryptedNewAccessToken = encryptData(newAccessToken);
        const encryptedNewRefreshToken = encryptData(newRefreshToken);

        localStorage.setItem("accessToken", encryptedNewAccessToken);
        localStorage.setItem("refreshToken", encryptedNewRefreshToken);

        onRefreshed(newAccessToken); // Notify all pending requests

        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        // Redirect to /landing on error
        localStorage.clear();
        window.location.href = "/login";
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false; // Reset the refreshing flag
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
