import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaPhoneVolume } from "react-icons/fa6";
import {
  FaFacebook,
  FaTelegram,
  FaYoutube,
  FaWhatsapp,
  FaRegEye,
  FaRegEyeSlash,
} from "react-icons/fa";

import "../../index.css";
import rightLogo from "../../assets/rightLogo.png";
import logo from "../../assets/logo.png";
import founder from "../../assets/founder.png";
import { useAuth } from "../../Context/AuthContext";
import Message from "../../Components/Message";
import Modal from "../../Components/Modal";

export default function Login() {
  const { signup, err, loading, successful } = useAuth();

  //FORM DATA
  const [gender, setGender] = useState("");
  const [name, setName] = useState("");
  const [guardianPhoneNumber, setGuardianPhoneNumber] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [academicLevel, setAcademicLevel] = useState("");
  const [school, setSchool] = useState("");
  const [governorate, setGovernorate] = useState("");
  const [area, setArea] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [errors, setErrors] = useState([]);

  //Alert states
  const [animationClass, setAnimationClass] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const handlePhoneModalOpen = () => {
    setModalOpen(true);
    setAnimationClass("slide-in");
  };

  const handlePhoneModalClose = () => {
    setModalOpen(false);
    setAnimationClass("slide-out");
  };

  const [formErrors, setFormErrors] = useState({
    name: "",
    gender: "",
    guardianPhoneNumber: "",
    phoneNumber: "",
    address: "",
    academicLevel: "",
    school: "",
    governorate: "",
    area: "",
    email: "",
    password: "",
  });

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  // Function to validate phone numbers
  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^[0-9]{10,15}$/;
    return phoneRegex.test(phoneNumber);
  };

  // Email validation
  const validateEmail = (email) => {
    // Simple regex for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    const newErrors = {};

    // Validation logic
    if (!name) {
      newErrors.name = "Full Name must not be empty";
    } else if (typeof name !== "string") {
      newErrors.name = "Name must be a string";
      // .trim() removes leading/trailing whitespace
    } else if (name.trim().length < 3) {
      newErrors.name = "Full Name must be at least 3 characters long";
    } else if (!/^[a-zA-Z\s]+$/.test(name)) {
      // Allow only letters and spaces
      newErrors.name = "Name must only contain letters and spaces";
    }
    if (!gender) newErrors.gender = "Gender must be selected";
    if (!guardianPhoneNumber)
      newErrors.guardianPhoneNumber =
        "Guardian’s Phone Number must not be empty";
    else if (!validatePhoneNumber(guardianPhoneNumber))
      newErrors.guardianPhoneNumber =
        "Please enter a valid Guardian’s Phone Number";
    if (!phoneNumber) newErrors.phoneNumber = "Phone Number must not be empty";
    else if (!validatePhoneNumber(phoneNumber))
      newErrors.phoneNumber = "Please enter a valid Phone Number";
    if (!address) newErrors.address = "Address must not be empty";
    if (!academicLevel)
      newErrors.academicLevel = "Academic Level must be selected";
    if (!school) newErrors.school = "School must not be empty";
    if (!governorate) newErrors.governorate = "Governorate must not be empty";
    if (!area) newErrors.area = "Area must not be empty";
    if (!email) {
      newErrors.email = "Email must not be empty";
    } else if (!validateEmail(email)) {
      newErrors.email = `"${email}" is not a valid email address`;
    }
    if (!password) {
      newErrors.password = ["Password must not be empty"];
    } else {
      // Detailed password validation
      const passwordErrors = [];
      if (password.length < 8)
        passwordErrors.push("Password must be at least 8 characters long");
      if (!/[A-Z]/.test(password))
        passwordErrors.push(
          "Password must contain at least 1 uppercase letter"
        );
      if (!/[a-z]/.test(password))
        passwordErrors.push(
          "Password must contain at least 1 lowercase letter"
        );
      if (!/\d/.test(password))
        passwordErrors.push("Password must contain at least 1 number");
      if (passwordErrors.length > 0) {
        newErrors.password = passwordErrors;
      }
    }

    setFormErrors(newErrors);

    //prevent sending a request if there is at least one error
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      console.log(errors);
      return;
    }

    await signup(
      name,
      gender,
      guardianPhoneNumber,
      phoneNumber,
      address,
      academicLevel,
      school,
      governorate,
      area,
      email,
      password
    );
  };

  // useCallback to ensure hideMessage is using latest values
  const hideMessage = useCallback(() => {
    if (err) {
      setAnimationClass("slide-out");
      setTimeout(() => setIsVisible(false), 500);
    } else if (successful) {
      setAnimationClass("zoom-out-animation");
      setTimeout(() => setIsVisible(false), 500);
    }
  }, [err, successful]);

  useEffect(() => {
    if (err || successful) {
      setIsVisible(true);
      setAnimationClass(err ? "slide-in" : "zoom-in-animation");
      const timer = setTimeout(hideMessage, 5000);

      return () => clearTimeout(timer);
    } else {
      setIsVisible(false);
    }
  }, [err, successful, hideMessage]);

  return (
    <div className="lg:bg-customBlue flex items-center justify-center min-h-screen">
      {/* Container to align left and right side equally */}
      <div className="flex flex-col lg:flex-row w-full max-w-lg lg:max-w-[1500px] lg:h-[800px]">
        {/* LEFT SIDE */}
        <div className="bg-white lg:flex-1 lg:flex lg:flex-col items-center justify-center p-[40px] hidden rounded-tl-[63px] rounded-bl-[63px]">
          <div className="flex flex-col items-center">
            {/* logo */}
            <Link to="/landing">
              <div className="flex justify-center h-[350px] w-[400px] pl-[40px] pt-[40px]">
                <img src={rightLogo} alt="" />
              </div>
            </Link>
          </div>
          <div className="flex justify-center p-[50px]">
            <ul className="flex items-center justify-center">
              <li className="p-3">
                <FaPhoneVolume
                  onClick={handlePhoneModalOpen}
                  className="hover:scale-125 duration-300 cursor-pointer w-[30px] h-[37px] md:w-[35px] md:h-[42px] lg:w-[40px] lg:h-[48px] text-[#1DB954]"
                />
              </li>
              <Modal
                isOpen={isModalOpen}
                onClose={handlePhoneModalClose}
                animationClass={animationClass}
                title="Give Us A Call"
                message="01559235216"
              />
              <li className="p-3">
                <a
                  href="https://www.facebook.com/youssef.sherif.math.instructor?mibextid=ZbWKwL"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebook className="hover:scale-125 duration-300 cursor-pointer w-[38px] h-[38px] md:w-[42px] md:h-[42px] lg:w-[48px] lg:h-[48px] text-[#4267B2]" />
                </a>
              </li>
              <li className="p-3">
                <a
                  href="https://T.me/eduhighteam"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTelegram className="hover:scale-125 duration-300 cursor-pointer w-[38px] h-[38px] md:w-[42px] md:h-[42px] lg:w-[48px] lg:h-[48px] text-[#24A1DE]" />
                </a>
              </li>
              <li className="p-3">
                <a
                  href="https://youtube.com/@youssefsherif28?si=HLYhtGyg8uvxAYIa"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaYoutube className="hover:scale-125 duration-300 cursor-pointer w-[38px] h-[38px] md:w-[42px] md:h-[42px] lg:w-[48px] lg:h-[48px] text-[#FF0000]" />
                </a>
              </li>
              <li className="p-3">
                <a
                  href="https://wa.link/o3mbr0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaWhatsapp className="hover:scale-125 duration-300 cursor-pointer w-[38px] h-[38px] md:w-[42px] md:h-[42px] lg:w-[48px] lg:h-[48px] text-[#25D366]" />
                </a>
              </li>
            </ul>
          </div>

          {/* founder logo */}
          <div className="flex justify-center items-center gap-5 pb-6">
            <h3 className="text-center pb-1 text-[#06315B] font-bold">
              Founded By
            </h3>
            <img src={founder} alt="founder" />
          </div>
        </div>

        {/* RIGHT SIDE */}
        {/* Socials */}
        <div className="flex-1 flex flex-col items-center lg:bg-customRed justify-center p-[20px] lg:p-[20px] rounded-[32px] lg:rounded-tl-[0px] lg:rounded-bl-[0px]">
          <div className="flex justify-center p-5 lg:hidden">
            <ul className="flex items-center justify-center">
              <li className="p-3">
                <FaPhoneVolume
                  onClick={handlePhoneModalOpen}
                  className="cursor-pointer w-[30px] h-[37px] md:w-[35px] md:h-[42px] lg:w-[40px] lg:h-[48px] text-[#1DB954]"
                />
                <Modal
                  isOpen={isModalOpen}
                  onClose={handlePhoneModalClose}
                  animationClass={animationClass}
                  title="Give Us A Call"
                  message="01559235216"
                />
              </li>
              <li className="p-3">
                <a
                  href="https://www.facebook.com/youssef.sherif.math.instructor?mibextid=ZbWKwL"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebook className="cursor-pointer w-[38px] h-[38px] md:w-[42px] md:h-[42px] lg:w-[48px] lg:h-[48px] text-[#4267B2]" />
                </a>
              </li>
              <li className="p-3">
                <a
                  href="https://T.me/eduhighteam"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTelegram className="cursor-pointer w-[38px] h-[38px] md:w-[42px] md:h-[42px] lg:w-[48px] lg:h-[48px] text-[#24A1DE]" />
                </a>
              </li>
              <li className="p-3">
                <a
                  href="https://youtube.com/@youssefsherif28?si=HLYhtGyg8uvxAYIa"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaYoutube className="cursor-pointer w-[38px] h-[38px] md:w-[42px] md:h-[42px] lg:w-[48px] lg:h-[48px] text-[#FF0000]" />
                </a>
              </li>
              <li className="p-3">
                <a
                  href="https://wa.link/o3mbr0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaWhatsapp className="cursor-pointer w-[38px] h-[38px] md:w-[42px] md:h-[42px] lg:w-[48px] lg:h-[48px] text-[#25D366]" />
                </a>
              </li>
            </ul>
          </div>

          <div className="lg:hidden flex flex-col items-center justify-center pb-6 ">
            {/* logo */}
            <Link to="/landing">
              <div className="flex justify-center">
                <img src={logo} alt="" />
              </div>
            </Link>
          </div>
          <div className="flex flex-col items-center justify-center bg-white max-w-[700px] px-[20px] lg:px-[40px] rounded-[32px] border-2 border-customRed lg:border-0 overflow-hidden">
            <div className="text-4xl pt-6 font-bold text-center text-neutral-800">
              Sign Up
            </div>
            <div className="self-stretch mt-2 text-base leading-5 text-center text-neutral-700">
              Welcome to Eduhigh. Create an account to enjoy all the services
              provided.
            </div>
            <div
              className={`w-full ${
                formErrors ? "lg:overflow-auto" : "lg:overflow-hidden"
              }`}
            >
              {/* ALERT POP IN */}
              {
                <Message
                  name={name}
                  isVisible={isVisible}
                  animationClass={animationClass}
                />
              }
              {/* ALERT POP IN */}
              <form className="flex flex-col items-center justify-center">
                {/* Full Name Input */}
                <div className="w-full flex flex-col items-center">
                  <input
                    type="text"
                    id="name"
                    placeholder="Full Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="px-3 py-2 mt-2.5 text-[14px] max-w-full bg-white rounded-xl border border-solid border-black border-opacity-20 w-[314px]"
                    required
                  />
                  {formErrors.name && (
                    <p className="text-red-500 text-sm mt-1">
                      {formErrors.name}
                    </p>
                  )}

                  {/* Gender Input */}
                  <select
                    id="gender"
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                    className="px-3 py-2 mt-2.5 text-[14px] max-w-full bg-white rounded-xl border border-solid border-black border-opacity-20 w-[314px]"
                  >
                    <option value="" disabled>
                      Select Gender
                    </option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                  {formErrors.gender && (
                    <p className="text-red-500 text-sm mt-1">
                      {formErrors.gender}
                    </p>
                  )}

                  {/* Guardian’s Phone Number Input */}
                  <input
                    type="tel"
                    id="parentNumber"
                    placeholder="Guardian’s Phone Number"
                    value={guardianPhoneNumber}
                    onChange={(e) => setGuardianPhoneNumber(e.target.value)}
                    className="px-3 py-2 mt-2.5 text-[14px] max-w-full bg-white rounded-xl border border-solid border-black border-opacity-20 w-[314px]"
                    required
                  />
                  {formErrors.guardianPhoneNumber && (
                    <p className="text-red-500 text-sm mt-1">
                      {formErrors.guardianPhoneNumber}
                    </p>
                  )}

                  {/* Phone Number Input */}
                  <input
                    type="tel"
                    id="studentNumber"
                    placeholder="Phone Number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    className="px-3 py-2 mt-2.5 text-[14px] max-w-full bg-white rounded-xl border border-solid border-black border-opacity-20 w-[314px]"
                    required
                  />
                  {formErrors.phoneNumber && (
                    <p className="text-red-500 text-sm mt-1">
                      {formErrors.phoneNumber}
                    </p>
                  )}

                  {/* Address Input */}
                  <input
                    type="text"
                    id="address"
                    placeholder="Address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    className="px-3 py-2 mt-2.5 text-[14px] max-w-full bg-white rounded-xl border border-solid border-black border-opacity-20 w-[314px]"
                    required
                  />
                  {formErrors.address && (
                    <p className="text-red-500 text-sm mt-1">
                      {formErrors.address}
                    </p>
                  )}

                  {/* Academic Level Input */}
                  <select
                    id="academicLevel"
                    value={academicLevel}
                    onChange={(e) => setAcademicLevel(e.target.value)}
                    className="px-3 py-2 mt-2.5 text-[14px] max-w-full bg-white rounded-xl border border-solid border-black border-opacity-20 w-[314px]"
                  >
                    <option value="" disabled>
                      Select Academic Level
                    </option>
                    <option value="Secondary School - Second Year">
                      Secondary School - Second Year
                    </option>
                    <option value="Secondary School - Third Year Literary">
                      Secondary School - Third Year Literary
                    </option>
                    <option value="Secondary School - Third Year Scientific">
                      Secondary School - Third Year Scientific
                    </option>
                    <option value="Faculty of Engineering">
                      Faculty of Engineering
                    </option>
                    <option value="Faculty of Computer Science">
                      Faculty of Computer Science
                    </option>
                    <option value="Faculty of Science">
                      Faculty of Science
                    </option>
                  </select>
                  {formErrors.academicLevel && (
                    <p className="text-red-500 text-sm mt-1">
                      {formErrors.academicLevel}
                    </p>
                  )}

                  {/* School Input */}
                  <input
                    type="text"
                    id="school"
                    placeholder="School"
                    value={school}
                    onChange={(e) => setSchool(e.target.value)}
                    className="px-3 py-2 mt-2.5 text-[14px] max-w-full bg-white rounded-xl border border-solid border-black border-opacity-20 w-[314px]"
                    required
                  />
                  {formErrors.school && (
                    <p className="text-red-500 text-sm mt-1">
                      {formErrors.school}
                    </p>
                  )}

                  {/* Governorate Input */}
                  <input
                    type="text"
                    id="governorate"
                    placeholder="Governorate"
                    value={governorate}
                    onChange={(e) => setGovernorate(e.target.value)}
                    className="px-3 py-2 mt-2.5 text-[14px] max-w-full bg-white rounded-xl border border-solid border-black border-opacity-20 w-[314px]"
                    required
                  />
                  {formErrors.governorate && (
                    <p className="text-red-500 text-sm mt-1">
                      {formErrors.governorate}
                    </p>
                  )}

                  {/* Area Input */}
                  <input
                    type="text"
                    id="area"
                    placeholder="Area"
                    value={area}
                    onChange={(e) => setArea(e.target.value)}
                    className="px-3 py-2 mt-2.5 text-[14px] max-w-full bg-white rounded-xl border border-solid border-black border-opacity-20 w-[314px]"
                    required
                  />
                  {formErrors.area && (
                    <p className="text-red-500 text-sm mt-1">
                      {formErrors.area}
                    </p>
                  )}

                  {/* Email Input */}
                  <input
                    type="text"
                    id="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="items-center px-3 py-2 mt-2.5 text-[14px] max-w-full bg-white rounded-xl border border-solid border-black border-opacity-20 w-[314px]"
                    required
                  />
                  {formErrors.email && (
                    <p className="text-red-500 text-sm mt-1">
                      {formErrors.email}
                    </p>
                  )}

                  {/* Password Input */}
                  <div className="relative pb-1">
                    <input
                      type={passwordVisible ? "text" : "password"}
                      id="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="px-3 py-2 mt-2.5 text-[14px] max-w-full bg-white rounded-xl border border-solid border-black border-opacity-20 w-[314px] pr-12" // Add right padding for icon
                    />
                    <button
                      type="button"
                      onClick={togglePasswordVisibility}
                      className="absolute right-4 top-1/2 transform -translate-y-[30%] text-gray-500"
                      aria-label="Toggle password visibility"
                    >
                      {passwordVisible ? (
                        <FaRegEye size={20} />
                      ) : (
                        <FaRegEyeSlash size={20} />
                      )}
                    </button>
                  </div>
                  {formErrors.password &&
                    Array.isArray(formErrors.password) && (
                      <div className="text-red-500 text-sm mt-2 ml-2">
                        {formErrors.password.map((error, index) => (
                          <p key={index}>{error}</p>
                        ))}
                      </div>
                    )}
                </div>
              </form>
            </div>
            <div className="flex justify-center">
              <button
                onClick={handleSignup}
                className="flex mb-3.5 items-center justify-center w-[321px] px-16 pt-5 pb-5 mt-4 max-w-full text-2xl font-bold leading-tight text-center text-white bg-customRed rounded-2xl hover:scale-105 duration-300"
                disabled={loading}
              >
                {loading ? (
                  <>
                    <svg
                      className="animate-spin h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                    >
                      <circle
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      />
                      <path d="M4 12a8 8 0 018-8v8H4z" fill="currentColor" />
                    </svg>
                    <span className="ml-2">Signing up</span>{" "}
                  </>
                ) : (
                  "Sign Up"
                )}
              </button>
            </div>
            <div className="pb-3 text-sm leading-tight text-center text-neutral-700">
              Already Registered?{" "}
              <Link to="/login">
                <span className="underline cursor-pointer">Login</span>
              </Link>
            </div>
          </div>
        </div>
        {/* founder */}
        <div className="flex justify-center items-center gap-5 pb-3 pt-8 lg:hidden">
          <h3 className="text-center pb-1 text-[#06315B] font-bold">
            Founded By
          </h3>
          <img src={founder} alt="" />
        </div>
      </div>
    </div>
  );
}
