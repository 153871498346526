import React, { useState } from "react";

const SessionModal = ({
  isOpen,
  onClose,
  onConfirm,
  sessionDetails,
  errors,
}) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [reserving, setReserving] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("");

  const handleClose = () => {
    setIsAnimating(true);
    setTimeout(() => {
      onClose();
      setIsAnimating(false);
      resetModal();
    }, 300);
  };

  const handleConfirm = async () => {
    if (!showInput) {
      setShowInput(true);
    } else {
      const isValidNumber = /^\d+$/.test(whatsappNumber);
      if (!isValidNumber) {
        setValidationError("Please enter a valid WhatsApp number.");
        return;
      }

      setReserving(true);
      await onConfirm(whatsappNumber);
      setReserving(false);
      handleClose();
    }
  };

  const resetModal = () => {
    setShowInput(false);
    setWhatsappNumber("");
    setValidationError("");
  };

  if (!isOpen && !isAnimating) return null;

  const name = sessionDetails?.name || "N/A";
  const startDate = sessionDetails
    ? new Date(sessionDetails.startDate).toLocaleString()
    : "N/A";
  const isReserved = sessionDetails?.isReserved;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70">
      <div
        className={`modal bg-white p-6 rounded-lg shadow-lg transition-all duration-300 ${
          isAnimating ? "modal-close" : "modal-open"
        }`}
      >
        <h2 className="text-2xl font-bold text-gray-800 mb-4 text-center">
          Session Details
        </h2>
        <div className="border border-gray-300 rounded-lg p-4 mb-4 bg-gray-50">
          <p className="text-gray-700">
            <strong>Name:</strong> {name}
          </p>
          <p className="text-gray-700">
            <strong>Start Date:</strong> {startDate}
          </p>
          <p className="text-gray-700">
            <strong>Reserved:</strong> {isReserved ? "Yes" : "No"}
          </p>
        </div>

        {isReserved ? (
          <p className="text-red-500 text-center mb-4">
            This session is already reserved.
          </p>
        ) : (
          <>
            <h2 className="text-lg text-gray-800 mb-4">
              {showInput
                ? "Please enter your WhatsApp number to reserve this session"
                : "Are you sure you want to reserve this session?"}
            </h2>

            {showInput && (
              <input
                type="tel"
                value={whatsappNumber}
                onChange={(e) => setWhatsappNumber(e.target.value)}
                placeholder="WhatsApp Number"
                className="border border-gray-300 rounded-lg p-2 mb-4 w-full text-center text-black"
              />
            )}
          </>
        )}

        {errors &&
          errors.map((error, index) => (
            <p key={index} className="text-red-400">
              {error}
            </p>
          ))}

        {validationError && (
          <p className="text-red-400 text-center">{validationError}</p>
        )}

        <div className="flex justify-between mt-6">
          <button
            className={`${
              isReserved
                ? "border-[1px] border-green-600 text-green-900"
                : "bg-green-600 hover:bg-green-700 text-white"
            } px-5 py-2 rounded-lg transition-colors ${
              reserving || isReserved ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={handleConfirm}
            disabled={reserving || isReserved}
          >
            {reserving
              ? "Reserving..."
              : isReserved
              ? "Reserved"
              : showInput
              ? "Reserve"
              : "Yes"}
          </button>
          <button
            className="bg-red-600 hover:bg-red-700 text-white px-5 py-2 rounded-lg transition-colors"
            onClick={handleClose}
          >
            {isReserved ? "Close" : "Cancel"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SessionModal;
