import { Link } from "react-router-dom";

import logo from "../assets/error_logo.png";

const NoPage = () => {
  const role = localStorage.getItem("role");

  return (
    <div
      id="error"
      className="flex flex-col items-center justify-center h-screen bg-gray-100"
    >
      <img className="h-[350px] w-[350px]" src={logo} alt="logo" />
      <h1 className="text-4xl font-bold text-red-600 mb-4 text-center">
        Sorry, this page isn't available.
      </h1>
      <p className="text-lg text-gray-700 text-center p-5">
        The link you followed may be broken, or the page may have been removed.
        Go back to the home page.
      </p>
      <p className="text-2xl text-gray-700 text-center animate-bounce">👇</p>

      <div className="p-5 space-x-3">
        {role === "User" ? (
          <Link to="/courses">
            <button className="bg-[#06315B] rounded-[20px] text-[18px] p-4 w-[150px] text-white font-bold">
              Go Back
            </button>
          </Link>
        ) : role === "Admin" ? (
          <Link to="/students">
            <button className="bg-[#06315B] rounded-[20px] text-[18px] p-4 w-[150px] text-white font-bold">
              Go Back
            </button>
          </Link>
        ) : (
          <Link to="/landing">
            <button className="bg-[#06315B] rounded-[20px] text-[18px] p-4 w-[150px] text-white font-bold">
              Go Back
            </button>
          </Link>
        )}
      </div>
    </div>
  );
};

export default NoPage;
