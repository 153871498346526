import React from "react";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { MdError } from "react-icons/md";
import { useAuth } from "../Context/AuthContext.js";

const Message = ({ name, isVisible, animationClass }) => {
  const { err, successful } = useAuth();

  return (
    <>
      {/* START OF SUCCESS MESSAGE */}
      {isVisible && successful && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-40">
          {/* Card */}
          <div
            className={`bg-white rounded-lg shadow-xl p-6 ${animationClass}`}
          >
            {/* Icon centered in the card */}
            <div className="flex items-center justify-center mb-4">
              <IoCheckmarkCircleOutline className="text-green-600" size={60} />
            </div>
            {/* Text content */}
            <div className="text-center">
              <h2 className="text-xl text-black font-bold mb-2">
                Congrats, {name}!
              </h2>
              <p className="text-black font-normal">
                You have signed up successfully. Wish you have a nice
                experience.
              </p>
            </div>
          </div>
        </div>
      )}
      {/* END OF SUCCESS MESSAGE */}

      {/* START OF REQUEST 400 ERRORS */}
      {isVisible && err && (
        <div
          className={`fixed top-4 left-[1%] md:left-[25%] lg:left-[40%] transform -translate-x-1/2 w-full max-w-md px-3 ${animationClass}`}
        >
          {/* <div className="bg-white text-[#ff6272] border-2 border-[#e23636] rounded-full shadow-lg flex items-start space-x-4 p-4"> */}
          <div className="bg-[#b13830] text-white lg:border-2 border-white rounded-full shadow-lg flex items-start space-x-2 p-3">
            <div className="flex items-center justify-center w-16 h-16 rounded-full">
              <MdError size={30} />
            </div>
            <div className="flex flex-col space-y-1">
              <h2 className="text-lg text-white font-bold">Error</h2>
              <p className="text-white text-base">{err}</p>
            </div>
          </div>
        </div>
      )}
      {/* END OF REQUEST 400 ERRORS */}
    </>
  );
};

export default Message;
