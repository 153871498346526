import React, { useContext, useEffect, useState } from "react";
import { GiWallet } from "react-icons/gi";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { FaCheckCircle } from "react-icons/fa"; // Checkmark icon
import CryptoJS from "crypto-js";

import axiosInstance from "../../Context/axiosInstance.js";
import { DarkModeContext } from "../../Context/DarkModeContext";
import walletIcon from "../../assets/walletIcon.png";

function WalletPage() {
  const { isDarkMode } = useContext(DarkModeContext);
  const userId = localStorage.getItem("userId");

  const [code, setCode] = useState("");
  const [balance, setBalance] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingReceipts, setLoadingReceipts] = useState(false);
  const [isRedeeming, setIsRedeeming] = useState(false);
  const [redeemSuccess, setRedeemSuccess] = useState(false);

  const decryptData = (encryptedData) => {
    const bytes = CryptoJS.AES.decrypt(
      encryptedData,
      process.env.REACT_APP_SECRET_KEY
    );
    return bytes.toString(CryptoJS.enc.Utf8);
  };

  useEffect(() => {
    const fetchData = async () => {
      // Set loading state based on page size
      if (pageSize) {
        setLoadingReceipts(true);
      } else {
        setLoading(true);
      }
      try {
        const response = await axiosInstance.get(
          `/users/${decryptData(
            userId
          )}/wallet?page=${page}&pageSize=${pageSize}`
        );
        setBalance(response.data.balance);
        setTransactions(response.data.transactions.response);
      } catch (error) {
        setErrors((prev) => [...prev, "Error fetching data"]);
      } finally {
        setLoading(false);
        setLoadingReceipts(false);
      }
    };
    fetchData();
  }, [userId, balance, page, pageSize]);

  useEffect(() => {
    let timer;
    if (redeemSuccess) {
      timer = setTimeout(() => {
        setRedeemSuccess(false);
      }, 2000);
    }
    return () => clearTimeout(timer); // Cleanup on unmount
  }, [redeemSuccess]);

  const handleRedeem = async () => {
    setIsRedeeming(true);
    setErrors([]);
    setRedeemSuccess(false);

    if (!code) {
      setErrors(["Code cannot be empty."]);
      setIsRedeeming(false);
      return;
    }

    try {
      const response = await axiosInstance.post(`/codes/redeem`, {
        codeValue: code,
      });
      setBalance((prev) => prev + response.data.amount);
      setCode("");
      setRedeemSuccess(true);

      // Reset redeemSuccess after 5 seconds
      setTimeout(() => {
        setRedeemSuccess(false);
      }, 2000);
    } catch (error) {
      if (error.response && error.response.data.errors) {
        const errorMessages = error.response.data.errors.map((err) => {
          return `${err.reason}`;
        });
        setErrors((prev) => [...prev, ...errorMessages]);
      } else {
        setErrors((prev) => [
          ...prev,
          error.response?.data?.detail || "Couldn't redeem; an error occurred.",
        ]);
      }
    } finally {
      setIsRedeeming(false);
    }
  };

  const loadMoreTransactions = () => {
    setPageSize((prevPageSize) => prevPageSize + 10); // Increase page size by 10
    setPage(1);
  };

  return (
    <div
      className={`flex items-center justify-center min-h-screen pt-[80px] lg:p-8 lg:pr-[50px] pb-[50px] ${
        isDarkMode ? "bg-[#181818]" : "bg-customBlue"
      }`}
    >
      <div className="flex flex-col lg:flex-row lg:space-x-6 space-y-8 lg:space-y-0">
        <div
          className={`flex flex-col gap-16 p-12 rounded-[35px] items-center justify-center ${
            isDarkMode ? "border-2 border-customRed" : "bg-white"
          }`}
        >
          <img src={walletIcon} alt="logo" className="w-55 h-28" />
          <input
            type="text"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            placeholder="Enter Code"
            className={`${
              isDarkMode ? "bg-[#292929] text-white" : "bg-[#D9D9D9] text-black"
            } w-full px-3 py-3 border border-gray-400 rounded-[10px]`}
          />
          <button
            onClick={handleRedeem}
            className="bg-customRed flex items-center gap-2 hover:scale-105 ease-in-out duration-300 text-white text-xl px-12 py-2 rounded-[10px]"
          >
            {isRedeeming ? (
              <>
                <svg
                  className="animate-spin h-5 w-5 inline-block"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                >
                  <circle
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  />
                  <path d="M4 12a8 8 0 018-8v8H4z" fill="currentColor" />
                </svg>
                Redeeming...
              </>
            ) : redeemSuccess ? (
              <>
                <FaCheckCircle className="inline-block mr-2 text-green-500" />
                Success!
              </>
            ) : (
              "Redeem"
            )}
          </button>
          {errors.length > 0 && (
            <div className="mt-2 text-red-600">
              {errors.map((error, index) => (
                <p key={index}>{error}</p>
              ))}
            </div>
          )}
        </div>

        <div className="flex flex-col items-center justify-center flex-grow">
          <div
            className={`flex flex-col p-6 rounded-lg items-center justify-center h-48 ${
              isDarkMode
                ? "bg-customRed"
                : "bg-customRed border-2 border-white text-white"
            }`}
          >
            <GiWallet className="mb-2" size={60} />
            <h2 className="text-xl pb-2 uppercase">Account Overview</h2>
            <div className="flex">
              <div className="border-2 border-white rounded-full py-1 px-4">
                <h3 className="flex text-base">
                  Available Balance:
                  <span className="flex items-center gap-2 pl-2">
                    {loading ? (
                      <svg
                        className="animate-spin h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                      >
                        <circle
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path d="M4 12a8 8 0 018-8v8H4z" fill="currentColor" />
                      </svg>
                    ) : (
                      `${isNaN(balance) ? "-" : balance} EGP`
                    )}
                  </span>
                </h3>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`flex flex-col p-6 rounded-[25px] items-center w-[350px] ${
            isDarkMode
              ? "border-2 border-white"
              : "border-2 border-white text-white"
          }`}
        >
          <FaMoneyBillTransfer size={60} className="mb-4" />
          <div>
            <h2 className="text-xl text-center mb-2">Transaction History</h2>
          </div>
          <div className="scrollable-div h-[300px] w-full overflow-y-auto flex flex-col items-center justify-start bg-gray-50 border border-gray-200 rounded-lg shadow-sm">
            {transactions.length === 0 ? (
              <p className="text-center text-gray-600">
                No Transactions Available
              </p>
            ) : (
              transactions.map((transaction) => (
                <div
                  key={transaction.id}
                  className={`${
                    isDarkMode
                      ? "bg-[#3a3a3a] hover:bg-[#3d3d3d] text-white"
                      : "bg-white hover:bg-gray-100 text-black"
                  } cursor-default w-full py-3 px-4 border-b last:border-b-0  transition duration-200`}
                >
                  <div className="flex justify-between items-center">
                    <p className="text-sm mt-1">
                      {new Date(transaction.createdAt).toLocaleString()}
                    </p>

                    <p className="text-lg text-green-600 text-center items-center">
                      {transaction.amount} EGP
                    </p>
                  </div>
                  <p>{transaction.description}</p>
                </div>
              ))
            )}
          </div>
          <button
            onClick={loadMoreTransactions}
            className="flex items-center gap-2 mt-4 bg-customRed text-white px-4 py-2 rounded-md"
          >
            {loadingReceipts ? (
              <div className="px-[23px]">
                <svg
                  className="animate-spin h-5 w-5 inline-block"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                >
                  <circle
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  />
                  <path d="M4 12a8 8 0 018-8v8H4z" fill="currentColor" />
                </svg>
              </div>
            ) : (
              "Load More"
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

export default WalletPage;
