import React, { useEffect, useState } from "react";

const CourseScrollingText = ({ course }) => {
  const [shouldScroll, setShouldScroll] = useState(false);
  const [animationDelay, setAnimationDelay] = useState(false);

  const scrollAnimation = {
    animation: `scroll 10s linear infinite`,
    display: "inline-block",
    whiteSpace: "nowrap",
  };

  const normalStyle = {
    display: "inline-block",
  };

  const containerStyle = {
    width: "100%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    position: "relative",
  };

  const keyframes = `
    @keyframes scroll {
      0% { transform: translateX(1%); opacity: 1; }
      90% { transform: translateX(-100%); opacity: 1; }
      95% { opacity: 0; }
      100% { transform: translateX(0); opacity: 1; }
    }
  `;

  useEffect(() => {
    const styleSheet = document.createElement("style");
    styleSheet.type = "text/css";
    styleSheet.innerText = keyframes;
    document.head.appendChild(styleSheet);

    return () => {
      document.head.removeChild(styleSheet); // Cleanup on unmount
    };
  }, [keyframes]);

  useEffect(() => {
    const threshold = 27; // Adjust based on your needs
    setShouldScroll(course.name.length > threshold);

    if (shouldScroll) {
      const timer = setTimeout(() => {
        setAnimationDelay(true);
      }, 2000); // 2 seconds delay

      return () => clearTimeout(timer); // Cleanup the timer
    }
  }, [course.name, shouldScroll]);

  return (
    <div style={containerStyle}>
      <h3
        className={`text-[17px] capitalize font-semibold pb-2`}
        style={shouldScroll && animationDelay ? scrollAnimation : normalStyle}
      >
        {course.name}
      </h3>
    </div>
  );
};

export default CourseScrollingText;
