import React, { useContext, useEffect, useState } from "react";
import CryptoJS from "crypto-js";
import { IoArrowBackCircle } from "react-icons/io5";

import { DarkModeContext } from "../../../Context/DarkModeContext.jsx";
import axiosInstance from "../../../Context/axiosInstance.js";
import { useNavigate } from "react-router-dom";

function EditProfilePage() {
  const { isDarkMode } = useContext(DarkModeContext);
  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useState(null);
  const [originalUserInfo, setOriginalUserInfo] = useState(null); // State to store original user info
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [successMessage, setSuccessMessage] = useState(""); // New state for success message

  const userId = localStorage.getItem("userId");

  const decryptData = (encryptedData) => {
    const bytes = CryptoJS.AES.decrypt(
      encryptedData,
      process.env.REACT_APP_SECRET_KEY
    );
    return bytes.toString(CryptoJS.enc.Utf8);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      setError({});
      try {
        const response = await axiosInstance.get(
          `/users/${decryptData(userId)}`
        );
        setUserInfo(response.data);
        setOriginalUserInfo(response.data); // Store original data
      } catch (err) {
        console.error("Error fetching user data:", err);
      } finally {
        setLoading(false);
      }
    };
    fetchUserData();
  }, [userId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const hasChanges = () => {
    return JSON.stringify(userInfo) !== JSON.stringify(originalUserInfo); // Check for changes
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError({});
    setSuccessMessage("");

    try {
      await axiosInstance.put(`/users/${decryptData(userId)}`, userInfo);
      setSuccessMessage("Profile successfully updated!");
      setOriginalUserInfo(userInfo); // Update original info to current state
    } catch (err) {
      if (err.response) {
        const { data } = err.response;

        if (data.errors && Array.isArray(data.errors)) {
          const errorMessages = data.errors.map((error, index) => (
            <div key={index}>{`${error.reason}`}</div>
          ));
          setError(
            errorMessages ||
              "Failed to update profile or password. Please try again."
          );
        } else {
          setError(
            data?.detail ||
              "Failed to update profile or password. Please try again."
          );
        }
      } else {
        // Network error or no response
        setError("Network error. Please check your connection.");
      }
    } finally {
      setLoading(false);
    }
  };

  // Function to determine input type based on field name
  const getInputType = (key) => {
    switch (key) {
      case "email":
        return "email";
      case "password":
        return "password";
      case "guardianPhoneNumber":
      case "phoneNumber":
        return "tel";
      default:
        return "text";
    }
  };

  return (
    <div
      className={`${
        isDarkMode ? "bg-[#1e1e1e] text-white" : "bg-customBlue text-black"
      } flex items-center justify-center min-h-screen p-6 pt-[80px] lg:p-8 lg:pr-[50px] pb-[50px] lg:pb-[0px]`}
    >
      <div className="bg-white text-black p-8 rounded-lg shadow-xl w-full max-w-4xl">
        <div
          onClick={() => navigate(-1)}
          className="cursor-pointer flex items-center mb-4 text-blue-500"
        >
          <IoArrowBackCircle className="mr-1" size={20} />
          <button>Back</button>
        </div>
        <h2 className="text-xl font-bold mb-4">Edit Profile</h2>
        {loading ? (
          <div className="flex justify-center items-center min-h-[510px]">
            {" "}
            {/* Set a min height */}
            <div className="loader">
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
            </div>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-[10px]">
              {userInfo &&
                Object.keys(userInfo)
                  .filter((key) => key !== "id")
                  .map((key) => (
                    <div className="mb-4" key={key}>
                      <label
                        htmlFor={key}
                        className="block text-sm font-medium"
                      >
                        {key.charAt(0).toUpperCase() +
                          key.slice(1).replace(/([A-Z])/g, " $1")}
                      </label>
                      {key === "academicLevel" || key === "gender" ? (
                        <select
                          id={key}
                          name={key}
                          value={userInfo[key]}
                          onChange={handleChange}
                          className="mt-1 p-2 w-full border rounded"
                        >
                          {key === "academicLevel" ? (
                            <>
                              <option value="Secondary School - Second Year">
                                Secondary School - Second Year
                              </option>

                              <option value="Secondary School - Third Year Literary">
                                Secondary School - Third Year Literary
                              </option>

                              <option value="Secondary School - Third Year Scientific">
                                Secondary School - Third Year Scientific
                              </option>

                              <option value="Faculty of Engineering">
                                Faculty of Engineering
                              </option>

                              <option value="Faculty of Computer Science">
                                Faculty of Computer Science
                              </option>

                              <option value="Faculty of Science">
                                Faculty of Science
                              </option>
                            </>
                          ) : (
                            <>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                            </>
                          )}
                        </select>
                      ) : (
                        <input
                          type={getInputType(key)}
                          id={key}
                          name={key}
                          value={userInfo[key]}
                          onChange={handleChange}
                          className="mt-1 p-2 w-full border rounded"
                        />
                      )}
                    </div>
                  ))}
            </div>
            {error && (
              <div className="text-red-500">
                <p>
                  {typeof error === "string"
                    ? error
                    : Object.keys(error).length > 0 && (
                        <div className="text-red-500">
                          <p>{error}</p>
                        </div>
                      )}
                </p>
              </div>
            )}
            {/* {Object.keys(error).length > 0 && (
              <div className="text-red-500">
                {Object.keys(error).map((key) => {
                  // Check if the error is an object
                  const errorMessage =
                    typeof error[key] === "object"
                      ? error[key].reason || error[key].name
                      : error[key];
                  return <p key={key}>{errorMessage}</p>;
                })}
              </div>
            )} */}
            {successMessage && (
              <div className="flex justify-center text-green-500 mt-4">
                {successMessage}
              </div> // Display success message
            )}
            <div className="flex justify-center mt-4">
              <button
                type="submit"
                className={`px-4 py-2 rounded ${
                  loading || !hasChanges()
                    ? "bg-blue-500 text-white opacity-50 cursor-not-allowed"
                    : "bg-blue-500 text-white hover:bg-blue-600"
                }`}
                disabled={loading || !hasChanges()}
              >
                {loading ? "Saving..." : "Save Changes"}
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

export default EditProfilePage;
