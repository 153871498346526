import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MdDelete, MdOutlineDelete } from "react-icons/md";
import {
  IoMdAdd,
  IoIosCloseCircleOutline,
  IoIosArrowBack,
  IoIosArrowForward,
} from "react-icons/io";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { format } from "date-fns";

import "../../../index.css";
import { DarkModeContext } from "../../../Context/DarkModeContext.jsx";
import axiosInstance from "../../../Context/axiosInstance.js";

function Sessions() {
  const { isDarkMode } = useContext(DarkModeContext);
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  // Retrieve values from URL parameters
  const pageFromUrl = parseInt(params.get("p"), 10) || 1;
  const pageSizeFromUrl = parseInt(params.get("ps"), 10) || 20;
  const searchQueryFromUrl = params.get("q") || "";
  const sortColumnFromUrl = params.get("sc") || "Name";
  const sortDirectionFromUrl = params.get("sd") || "asc";

  const [page, setPage] = useState(pageFromUrl);
  const [pageSize, setPageSize] = useState(pageSizeFromUrl);
  const [searchQuery, setSearchQuery] = useState(searchQueryFromUrl);
  const [sortColumn, setSortColumn] = useState(sortColumnFromUrl);
  const [sortDirection, setSortDirection] = useState(sortDirectionFromUrl);

  const [sessions, setSessions] = useState([]);
  const [selectedSession, setSelectedSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(1);

  // States for Session options modal
  const [isModalOpen, setModalOpen] = useState(false);
  const [animationClass, setAnimationClass] = useState("");

  //State for Confirmation Modal
  const [isConfirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [deletingSession, setDeletingSession] = useState(false);

  // Form Data
  const [loadingAddSession, setLoadingAddSession] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    startDate: "",
    endDate: "",
    location: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: "",
    description: "",
    startDate: "",
    endDate: "",
    location: "",
  });
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    // Reset to page 1 whenever the search query changes
    setPage(1);
  }, [searchQuery]);

  // GET Request: Fetching Data
  const fetchSessions = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axiosInstance.get(
        `/sessions?page=${page}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&search=${encodeURIComponent(
          searchQuery
        )}`
      );

      setSessions(response.data.response);
      setTotalPages(Math.ceil(response.data.totalCount / pageSize));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Unauthorized error (token expired or invalid)
        // The Axios interceptor will handle the token refresh and retry.
      } else {
        setError(error.message || "An unexpected error occurred.");
      }
    } finally {
      setLoading(false);
    }
  }, [page, pageSize, searchQuery, sortColumn, sortDirection]);

  useEffect(() => {
    fetchSessions();
  }, [fetchSessions]);

  const handleAddSessionModalOpen = () => {
    setModalOpen(true);
    setAnimationClass("slide-in");
  };

  const handleAddSession = async (e) => {
    e.preventDefault();
    setLoadingAddSession(true);
    const newErrors = {};

    // Basic validation
    if (!formData.name) newErrors.name = "Name is required.";
    if (!formData.description)
      newErrors.description = "Description is required.";
    if (!formData.startDate) newErrors.startDate = "Start Date is required.";
    if (!formData.endDate) newErrors.endDate = "End Date is required.";
    if (!formData.location) newErrors.location = "Location is required.";

    setFormErrors(newErrors);

    // If there are errors, return early
    if (Object.keys(newErrors).length > 0) {
      setLoadingAddSession(false);
      return;
    }

    try {
      const submissionData = {
        ...formData,
        startDate: format(formData.startDate, "dd/MM/yyyy HH:mm"),
        endDate: format(formData.endDate, "dd/MM/yyyy HH:mm"),
      };

      const response = await axiosInstance.post("/sessions", submissionData);
      setSessions([...sessions, response.data]);
      setModalOpen(false);
    } catch (error) {
      const errorMessages = error.response?.data?.errors || [
        error.response?.data?.message || "An error occurred. Please try again.",
      ];
      setErrors(errorMessages);
    } finally {
      setLoadingAddSession(false);
      fetchSessions();
    }
  };

  // DELETE Request: Removing a session
  const handleDeleteSession = () => {
    setConfirmDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    setDeletingSession(true);
    if (selectedSession.length > 0) {
      try {
        await Promise.all(
          selectedSession.map((id) => axiosInstance.delete(`/sessions/${id}`))
        );
        setSessions(
          sessions.filter((session) => !selectedSession.includes(session.id))
        );
        setSelectedSessions([]);
      } catch (error) {
        const errorMsg = error.response?.data;
        const message = errorMsg
          ? `${errorMsg.detail || "An error occurred."}`
          : "An error occurred. Please try again.";
        setErrors((prev) => [...prev, message]);
      } finally {
        setConfirmDeleteModalOpen(false);
        setDeletingSession(false);
      }
    }
  };

  const handleFormChange = (value, field) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  //FOR HANDLING SESSIONS
  const handleSessionPage = (id, name) => {
    if (id) {
      const queryParams = new URLSearchParams({
        p: page,
        ps: pageSize,
        q: searchQuery,
        sc: sortColumn,
        sd: sortDirection,
      }).toString();

      navigate(`/sessions/${id}?${queryParams}`, {
        state: { name },
      });
    } else {
      console.error("Session ID is undefined");
    }
  };

  // Handle selection of Sessions
  const handleSelectSessions = (sessionId) => {
    setSelectedSessions((prevSelected) =>
      prevSelected.includes(sessionId)
        ? prevSelected.filter((id) => id !== sessionId)
        : [...prevSelected, sessionId]
    );
  };

  // Control sorting direction
  const toggleSortDirection = (column) => {
    if (sortColumn === column) {
      setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const handleAddSessionModalClose = () => {
    setAnimationClass("slide-out");
    setModalOpen(false);
    setLoadingAddSession(false);
    setFormErrors("");
    setErrors([]);
    setFormData({
      name: "",
      description: "",
      startDate: "",
      endDate: "",
      location: "",
    });
  };

  // Handlers for pagination
  const goToNextPage = () => {
    navigate(+1);
  };

  const goToPreviousPage = () => {
    navigate(-1);
  };

  const formattedPath = `${location.pathname
    .slice(1) // Remove leading slash
    .replace(/[_-]/g, " ") // Replace underscores or hyphens with spaces
    .replace(/\b\w/g, (char) => char.toUpperCase()) // Capitalize first letter of each word
    .replace(/\//g, " > ")}
          > `;

  return (
    <div
      className={`flex flex-col lg:flex-row lg:items-start ${
        isDarkMode ? "bg-[#292929] text-black" : "bg-white text-black"
      } p-4 lg:p-8 min-h-screen`}
    >
      <div
        className={`${
          isDarkMode ? "bg-[#1e1e1e] text-white" : "bg-white text-black"
        } p-4 lg:p-8 mt-[40px] lg:mt-0 rounded-lg shadow-xl flex-1`}
      >
        {/* PATH AND CONTROLS */}
        <div className="lg:flex w-full justify-between items-center mb-4 text-gray-700 text-sm capitalize">
          <div className="flex gap-1 items-center">
            <button
              onClick={goToPreviousPage}
              className="w-[24px] h-[20px] flex justify-center items-center bg-gray-300 rounded-[6px] disabled:opacity-50"
            >
              <IoIosArrowBack />
            </button>
            <button
              onClick={goToNextPage}
              className="w-[24px] h-[20px] flex justify-center items-center bg-gray-300 rounded-[6px] disabled:opacity-50"
            >
              <IoIosArrowForward />
            </button>

            <div
              className={`${
                isDarkMode ? "text-white" : "text-black"
              } text-sm capitalize px-2`}
            >
              <span>{formattedPath}</span>
            </div>
          </div>

          <div className="flex items-center justify-center p-4 lg:p-0 lg:justify-start w-full sm:w-auto">
            <input
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className={`${
                isDarkMode
                  ? "bg-[#292929] border border-gray-500 text-white"
                  : "bg-gray-100 border border-gray-300 text-black"
              } px-4 py-2 rounded-lg w-full sm:w-[200px] md:w-[680px]`}
            />
          </div>

          <div className="flex flex-col lg:flex-row lg:gap-2">
            <button
              onClick={(id) => handleAddSessionModalOpen(id)}
              className={`flex items-center mb-2 lg:mb-0 px-4 py-2 lg:px-6 lg:py-3 justify-center bg-[#2264E5] text-white rounded-lg transition-opacity`}
            >
              <IoMdAdd size={30} className="pr-2" />
              Add Session
            </button>
            <button
              onClick={() => {
                selectedSession.forEach(handleDeleteSession);
              }}
              disabled={selectedSession.length === 0}
              className={`flex items-center mb-2 lg:mb-0 px-4 py-2 lg:px-6 lg:py-3 justify-center bg-red-500 text-white rounded-lg transition-opacity ${
                selectedSession.length === 0
                  ? "opacity-40 cursor-not-allowed"
                  : "hover:bg-red-600"
              }`}
            >
              <MdOutlineDelete size={30} className="pr-2" />
              Delete Session
            </button>
          </div>
        </div>

        {/*/////////////// CONFIRM DELETE MODAL//////////////////////// */}
        {isConfirmDeleteModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
            <div
              className={`relative flex flex-col ${
                isDarkMode ? "bg-white text-black" : "bg-white text-black"
              } p-6 rounded-lg shadow-lg w-80`}
            >
              <div className="flex justify-center pb-4">
                <MdDelete className="text-customRed justify-center" size={50} />
              </div>
              <h2 className="text-lg font-bold text-center">
                Confirm Deletion
              </h2>
              <p className="text-center mt-2">
                Are you sure you want to delete
                {selectedSession.length > 1
                  ? " these sessions?"
                  : " this session?"}
              </p>
              <div className="flex justify-around mt-4">
                <button
                  onClick={() => handleConfirmDelete()}
                  className="bg-red-500 text-white px-4 py-2 rounded"
                >
                  {deletingSession ? "Deleting..." : "Yes"}
                </button>
                <button
                  onClick={() => setConfirmDeleteModalOpen(false)}
                  className="bg-gray-500 text-white px-4 py-2 rounded"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {/*/////////////// ADD Session MODAL//////////////////////// */}
        {isModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
            <div
              className={`${animationClass} relative flex flex-col ${
                isDarkMode ? "bg-white text-black" : "bg-white text-black"
              } p-6 rounded-[35px] shadow-lg w-[500px]`}
            >
              {/* Icon container */}
              <div className="absolute top-[-30px] left-1/2 transform -translate-x-1/2 flex justify-center items-center">
                <div className="bg-white w-[80px] h-[60px] shadow-md rounded-full border border-gray-300 flex justify-center items-center">
                  <IoMdAdd size={40} className="text-[#2264E5] animate-pulse" />
                </div>
              </div>
              {/* Close icon */}
              <IoIosCloseCircleOutline
                onClick={handleAddSessionModalClose}
                className="absolute top-4 right-4 text-2xl cursor-pointer text-gray-500 hover:text-red-500"
              />

              {/* Add Form */}
              <h2 className="text-2xl font-bold text-center mt-4 mb-4">
                Create A Session
              </h2>
              <form className="grid grid-cols-2 gap-6 mt-4">
                <div className="relative col-span-2">
                  <input
                    type="text"
                    id="name"
                    placeholder=" "
                    value={formData.name}
                    onChange={(e) =>
                      handleFormChange(e.target.value, e.target.id)
                    }
                    className="floating-input px-3 py-2 mt-2.5 text-[14px] min-w-full bg-white rounded-xl border border-solid border-black border-opacity-20"
                    required
                  />
                  <label htmlFor="name" className="floating-label">
                    Name
                  </label>
                  {formErrors.name && (
                    <p className="text-red-500 text-sm mt-1">
                      {formErrors.name}
                    </p>
                  )}
                </div>

                <div className="relative col-span-2">
                  <label className="block mb-1">Description:</label>
                  <textarea
                    type="text"
                    id="description"
                    placeholder="What seems to be the issue?"
                    value={formData.description}
                    onChange={(e) =>
                      handleFormChange(e.target.value, e.target.id)
                    }
                    className="border rounded p-2 w-full"
                  />
                  {formErrors.description && (
                    <p className="text-red-500 text-sm mt-1">
                      {formErrors.description}
                    </p>
                  )}
                </div>
                <div className="relative">
                  <Datetime
                    selected={formData.startDate}
                    onChange={(date) => handleFormChange(date, "startDate")}
                    dateFormat="DD/MM/YYYY"
                    timeFormat="hh:mm A"
                    inputProps={{
                      className:
                        "floating-input px-4 py-3 mt-2.5 text-sm bg-white rounded-[35px] border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition duration-200 w-full",
                      placeholder: "Start Date",
                      required: true,
                    }}
                  />
                  {formErrors.startDate && (
                    <p className="text-red-500 text-sm mt-1">
                      {formErrors.startDate}
                    </p>
                  )}
                </div>

                <div className="relative">
                  <Datetime
                    selected={formData.endDate}
                    onChange={(date) => handleFormChange(date, "endDate")}
                    dateFormat="DD/MM/YYYY"
                    timeFormat="hh:mm A"
                    inputProps={{
                      className:
                        "floating-input px-3 py-2 mt-2.5 font-light text-[14px] max-w-full bg-white rounded-[12px] border border-solid border-black border-opacity-20 w-full", // Changed font-normal to font-light and rounded-xl to rounded-[12px]
                      placeholder: "End Date",
                      required: true,
                    }}
                  />
                  {formErrors.endDate && (
                    <p className="text-red-500 text-sm mt-1">
                      {formErrors.endDate}
                    </p>
                  )}
                </div>

                <div className="relative col-span-2">
                  <input
                    type="text"
                    id="location"
                    placeholder=""
                    value={formData.location}
                    onChange={(e) =>
                      handleFormChange(e.target.value, e.target.id)
                    }
                    className="floating-input px-3 py-2 mt-2.5 text-[14px] min-w-full bg-white rounded-xl border border-solid border-black border-opacity-20 w-[314px]"
                    required
                  />
                  <label htmlFor="location" className="floating-label">
                    Location
                  </label>
                  {formErrors.location && (
                    <p className="text-red-500 text-sm mt-1">
                      {formErrors.location}
                    </p>
                  )}
                </div>
              </form>

              {/* DISPLAY ADD ERRORS */}
              {errors.length > 0 && (
                <div className="text-red-500 text-center py-2">
                  {errors.map((error, index) => (
                    <p key={index}>
                      {typeof error === "string"
                        ? error
                        : error && typeof error === "object"
                        ? `${error.reason}`
                        : "An unknown error occurred."}
                    </p>
                  ))}
                </div>
              )}

              <div className="flex justify-center items-center mt-4">
                <button
                  onClick={handleAddSession}
                  className="flex items-center rounded-md bg-[#2264E5] hover:bg-sky-500 py-2 px-4 border border-transparent text-center text-sm text-white transition-all shadow-sm hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                  type="button"
                  disabled={loadingAddSession}
                >
                  {loadingAddSession ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      className="w-4 h-4 mr-1.5 animate-spin"
                    >
                      <circle
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                        fill="none"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-4 h-4 mr-1.5"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.5 3.75a6 6 0 0 0-5.98 6.496A5.25 5.25 0 0 0 6.75 20.25H18a4.5 4.5 0 0 0 2.206-8.423 3.75 3.75 0 0 0-4.133-4.303A6.001 6.001 0 0 0 10.5 3.75Zm2.03 5.47a.75.75 0 0 0-1.06 0l-3 3a.75.75 0 1 0 1.06 1.06l1.72-1.72v4.94a.75.75 0 0 0 1.5 0v-4.94l1.72 1.72a.75.75 0 1 0 1.06-1.06l-3-3Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  )}
                  {loadingAddSession ? "Adding..." : "Add session"}
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="max-w-[320px] md:max-w-[700px] lg:max-w-full overflow-x-auto pb-4">
          <table className="min-w-full divide-y divide-gray-200 ">
            <thead
              className={`${
                isDarkMode ? "bg-[#1e1e1e] text-white" : "bg-white text-black"
              }`}
            >
              <tr>
                <th className="px-4 py-2 text-left font-medium text-gray-500 uppercase tracking-wider">
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      setSelectedSessions(
                        e.target.checked
                          ? sessions.map((session) => session.id)
                          : []
                      )
                    }
                    checked={selectedSession.length === sessions.length}
                  />
                </th>
                <th
                  onClick={() => toggleSortDirection("Name")}
                  className="cursor-pointer px-4 py-2 text-center text-base font-medium text-gray-500 uppercase tracking-wider"
                >
                  Name
                  {sortColumn === "Name" && (
                    <span className="text-sm px-1 items-center text-blue-400">
                      {sortDirection === "asc" ? "▲" : "▼"}
                    </span>
                  )}
                </th>
                <th
                  onClick={() => toggleSortDirection("StartDate")}
                  className="cursor-pointer px-4 py-2 text-center text-base font-medium text-gray-500 uppercase tracking-wider"
                >
                  Start Date
                  {sortColumn === "StartDate" && (
                    <span className="text-sm px-1 items-center text-blue-400">
                      {sortDirection === "asc" ? "▲" : "▼"}
                    </span>
                  )}
                </th>
              </tr>
            </thead>
            <tbody
              className={`${
                isDarkMode ? "text-white" : "text-black"
              } divide-y divide-gray-200`}
            >
              {sessions.length > 0 &&
                sessions.map((session) => (
                  <tr className="cursor-pointer" key={session.id}>
                    <td className="px-4 py-2 whitespace-nowrap font-medium">
                      <input
                        type="checkbox"
                        checked={selectedSession.includes(session.id)}
                        onChange={() => handleSelectSessions(session.id)}
                      />
                    </td>
                    <td
                      onClick={() =>
                        handleSessionPage(session.id, session.name)
                      }
                      className="px-4 py-2 whitespace-nowrap text-base text-center"
                    >
                      {session.name}
                    </td>
                    <td
                      onClick={() =>
                        handleSessionPage(session.id, session.name)
                      }
                      className="px-4 py-2 whitespace-nowrap text-base text-center text-[#2083D8]"
                    >
                      {new Date(session.startDate).toLocaleString("en-EG", {
                        timeZone: "Africa/Cairo",
                      })}
                    </td>
                  </tr>
                ))}
              {!sessions.length && !loading && (
                <tr className="h-[650px]">
                  <td
                    colSpan="7"
                    className={`${
                      isDarkMode ? "text-white" : "text-gray-500"
                    } text-center text-lg text-gray-500`}
                  >
                    No Sessions found
                  </td>
                </tr>
              )}
              {loading && (
                <tr>
                  <td
                    colSpan="6"
                    className={`${
                      isDarkMode ? "text-white" : "text-gray-500"
                    } px-2 py-5 text-center text-lg`}
                  >
                    <div className="flex justify-center items-center h-[650px]">
                      <div className="loader">
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {error && (
          <tr>
            <td
              colSpan="6"
              className={`${
                isDarkMode ? "text-white" : "text-gray-500"
              } px-2 py-5 text-center text-lg`}
            >
              No Sessions Available
            </td>
          </tr>
        )}
        <div className="flex items-center justify-end py-4 text-black">
          <label
            htmlFor="pageSize"
            className={`${
              isDarkMode ? "text-white" : "text-gray-700"
            } mr-2 text-sm`}
          >
            Sessions per page:
          </label>
          <select
            id="pageSize"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
              setPage(1);
            }}
            className="px-4 py-2 border border-gray-300 rounded-lg"
          >
            {[20, 30, 50].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
        <div className="mt-4 flex flex-col sm:flex-row justify-between items-center">
          <button
            onClick={() => handlePageChange(page - 1)}
            disabled={page <= 1}
            className={`px-4 py-2 bg-gray-300 text-black rounded-lg ${
              page <= 1 ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-400"
            }`}
          >
            Previous
          </button>
          <span className={"text-lg mt-2 sm:mt-0"}>
            Page {page} of {totalPages}
          </span>
          <button
            onClick={() => handlePageChange(page + 1)}
            disabled={page >= totalPages}
            className={`px-4 py-2 bg-gray-300 text-black rounded-lg ${
              page >= totalPages
                ? "opacity-50 cursor-not-allowed"
                : "hover:bg-gray-400"
            }`}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default Sessions;
