import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FaLock, FaShoppingCart } from "react-icons/fa";
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoIosCheckmarkCircle,
} from "react-icons/io";
import { MdError } from "react-icons/md";
import CryptoJS from "crypto-js";

//Contexts
import "../../index.css";
import axiosInstance from "../../Context/axiosInstance.js";
import CourseScrollingText from "../../Components/CourseScrollingText.jsx";
import { DarkModeContext } from "../../Context/DarkModeContext.jsx";

function StudentCoursePage() {
  const { isDarkMode } = useContext(DarkModeContext);
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const pageFromUrl = parseInt(params.get("page"), 10) || 1;
  const pageSizeFromUrl = parseInt(params.get("pageSize"), 10) || 20;

  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState("");
  const [animationClass, setAnimationClass] = useState("");

  // New state variables for api Call
  const [page, setPage] = useState(pageFromUrl);
  const [pageSize, setPageSize] = useState(pageSizeFromUrl);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  //WALLET SHIT
  const [balance, setBalance] = useState(0);

  // State for purchase confirmation modal
  const [mapErrors, setMapErrors] = useState(null);
  const [isConfirmPurchaseModalOpen, setConfirmPurchaseModalOpen] =
    useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [purchaseCourse, setPurchaseCourse] = useState(false);

  const userId = localStorage.getItem("userId");

  useEffect(() => {
    setPage(pageFromUrl);
    setPageSize(pageSizeFromUrl);
  }, [pageFromUrl, pageSizeFromUrl]);

  // utility function to decrypt data
  const decryptData = (encryptedData) => {
    const bytes = CryptoJS.AES.decrypt(
      encryptedData,
      process.env.REACT_APP_SECRET_KEY
    );
    return bytes.toString(CryptoJS.enc.Utf8);
  };

  useEffect(() => {
    const fetchBalance = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(
          `/users/${decryptData(userId)}/wallet`
        );
        setBalance(response.data.balance);
      } finally {
        setLoading(false);
      }
    };
    fetchBalance();
  }, [userId]);

  // Fetch courses + Memoize fetchData
  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `/users/${decryptData(
          userId
        )}/courses?page=${page}&pageSize=${pageSize}&search=${encodeURIComponent(
          searchQuery
        )}`
      );
      setCourses(response.data.response);
      setTotalPages(Math.ceil(response.data.totalCount / pageSize));
    } catch (error) {
      console.error("Error fetching courses:", error);
      if (error.response && error.response.status === 401) {
        // Handle unauthorized error
      } else {
        setError(error.message || "An unexpected error occurred.");
      }
    } finally {
      setLoading(false);
    }
  }, [userId, page, pageSize, searchQuery]); // Add dependencies here

  // Call fetchData when dependencies change
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Handlers for pagination
  const goToNextPage = () => {
    navigate(+1);
  };

  const goToPreviousPage = () => {
    navigate(-1);
  };

  const formattedPath = `${location.pathname
    .slice(1) // Remove leading slash
    .replace(/[_-]/g, " ") // Replace underscores or hyphens with spaces
    .replace(/\b\w/g, (char) => char.toUpperCase()) // Capitalize first letter of each word
    .replace(/\//g, " > ")}
    > `;

  const handlePageChange = (newPage) => {
    setPage(newPage);
    navigate(`?page=${newPage}&pageSize=${pageSize}`); // Update URL
  };

  const handlePageSizeChange = (e) => {
    const newSize = Number(e.target.value);
    setPageSize(newSize);
    setPage(1);
    navigate(`?page=1&pageSize=${newSize}`); // Reset to first page
  };

  // Updated handler for course click
  const handleCourseClick = (course) => {
    if (course.purchased) {
      const courseName = course.name
        .replace(/\s+/g, "_") // Replace spaces with underscores
        .replace(/\//g, "-") // Replace slashes with hyphens
        .toLowerCase();

      const safeCourseName = encodeURIComponent(courseName);

      navigate(`/courses/${safeCourseName}`, {
        state: { courseId: course.id, course },
      });
    }
  };

  const handleOpenPurchaseModal = (course) => {
    setAnimationClass("slide-in");
    setSelectedCourse(course);
    setConfirmPurchaseModalOpen(true);
  };

  const handleCourseBuy = async (event, selectedCourse) => {
    event.preventDefault(); // Prevent the default form submission

    if (!selectedCourse) return;

    setPurchaseCourse(true);
    setLoading(true);

    try {
      const response = await axiosInstance.post(
        `/courses/${selectedCourse.id}/purchase`,
        { courseId: selectedCourse.id }
      );

      if (response.status === 200) {
        setMessage(`Congratulations! You've successfully purchased the course`);
        setBalance((prevBalance) => prevBalance - selectedCourse.price);

        // Wait for 3 seconds before closing the modal
        setTimeout(async () => {
          await fetchData(); // Call fetchData to refetch courses

          setConfirmPurchaseModalOpen(false);
          setSelectedCourse(null);
        }, 3000);
      }
    } catch (error) {
      setMapErrors(error.response?.data?.detail);
    } finally {
      setLoading(false);
      setPurchaseCourse(false);
    }
  };

  return (
    <div
      className={`flex flex-col min-h-screen ${
        isDarkMode ? "bg-[#181818] text-white" : "bg-customBlue text-black"
      }`}
    >
      <main className="flex-1 p-8">
        {/* Container for cards */}
        <div
          className={`${
            isDarkMode ? "bg-[#1e1e1e] text-white" : "bg-white text-black"
          } p-8 mt-1 rounded-lg shadow-lg`}
        >
          {/* PATH AND CONTROLS */}
          <div className="lg:flex w-full justify-between items-center mb-4 text-gray-700 text-sm capitalize">
            <div className="flex gap-1 items-center">
              <button
                onClick={goToPreviousPage}
                className="w-[24px] h-[20px] flex justify-center items-center bg-gray-300 rounded-[6px] disabled:opacity-50"
              >
                <IoIosArrowBack />
              </button>
              <button
                onClick={goToNextPage}
                className="w-[24px] h-[20px] flex justify-center items-center bg-gray-300 rounded-[6px] disabled:opacity-50"
              >
                <IoIosArrowForward />
              </button>

              <div
                className={`${
                  isDarkMode ? "text-white" : "text-black"
                } text-sm capitalize px-2`}
              >
                <span>{formattedPath}</span>
              </div>
            </div>

            <div className="flex items-center justify-center gap-2 pt-2 lg:pt-0 pb-2 lg:pb-0 md:justify-start w-full sm:w-auto">
              <input
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className={`${
                  isDarkMode
                    ? "bg-[#292929] border border-gray-500 text-white"
                    : "bg-gray-100 border border-gray-300 text-black"
                } px-4 py-2 rounded-lg w-full sm:w-[200px] md:w-[680px]`}
              />
            </div>

            <div
              className={`flex justify-center items-center ${
                isDarkMode
                  ? "bg-customRed text-white"
                  : "bg-customBlue text-white"
              } text-sm rounded-[25px] p-3 w-full sm:w-auto md:w-auto`}
            >
              <span>
                Balance: <span className="font-bold"> {balance} EGP</span>
              </span>
            </div>
          </div>

          <h2 className="text-xl font-semibold mb-4">Courses for You</h2>

          {/* Loader and Course Display */}
          <div className="flex flex-col items-center">
            {loading ? (
              <div className="flex justify-center items-center h-[850px]">
                <div className="loader">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              </div>
            ) : error ? (
              <p className="text-red-600">Failed To Fetch Courses</p>
            ) : courses.length === 0 ? (
              <div className="col-span-full text-center text-[20px] p-4">
                <p className={`${isDarkMode ? "text-white" : "text-gray-700"}`}>
                  No Available Courses
                </p>
              </div>
            ) : (
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-6">
                {courses.map((course) => (
                  <div
                    key={course.id}
                    className={`flex flex-col ${
                      course.purchased &&
                      "cursor-pointer hover:scale-105 transition-transform duration-500 ease-in-out"
                    } rounded-lg shadow-md overflow-hidden`}
                    style={{
                      minWidth: "100px",
                      maxWidth: "350px",
                      minHeight: "350px",
                    }}
                    onClick={() => handleCourseClick(course)}
                  >
                    {/* Course content */}
                    <img
                      src={course.courseImageUrl}
                      alt=""
                      className="w-full h-48 object-cover"
                    />
                    <div className="p-4 flex-grow flex flex-col justify-between">
                      <div className="flex items-center">
                        <h3 className="text-[17px] w-[500px] font-semibold truncate pb-2">
                          <CourseScrollingText course={{ name: course.name }} />
                        </h3>
                      </div>
                      <p className="text-[#2196F3]">
                        Price: {course.price} EGP
                      </p>
                      <button
                        onClick={() =>
                          !course.purchased && handleOpenPurchaseModal(course)
                        }
                        disabled={course.purchased}
                        className={`flex items-center justify-center uppercase font-semibold ${
                          course.purchased === true
                            ? "bg-green-600"
                            : "bg-customRed hover:scale-105 transition-transform duration-500 ease-in-out"
                        } text-white py-2 px-4 rounded-lg w-full mt-auto`}
                      >
                        {course.purchased === false ? (
                          <span className="flex items-center">
                            <FaLock className="mr-2" />
                            Buy
                          </span>
                        ) : (
                          <span className="flex items-center">Unlocked</span>
                        )}
                      </button>
                    </div>

                    {/*/////////////// Confirmation Modal //////////////////////// */}
                    {isConfirmPurchaseModalOpen &&
                      selectedCourse &&
                      selectedCourse.id === course.id && (
                        <div
                          className={`fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50`}
                        >
                          <div
                            className={`${animationClass} relative flex flex-col ${
                              isDarkMode
                                ? "bg-gray-800 text-white"
                                : "bg-white text-black"
                            } p-6 rounded-lg shadow-lg w-80 h-auto`}
                          >
                            <div className="flex justify-center pb-4">
                              {message ? (
                                <IoIosCheckmarkCircle
                                  className="text-green-400"
                                  size={50}
                                />
                              ) : (
                                <FaShoppingCart
                                  className="text-slate-600"
                                  size={50}
                                />
                              )}
                            </div>
                            <h2 className="text-lg font-bold text-center">
                              {message || "Confirm Purchase"}
                            </h2>
                            {!message && (
                              <p className="text-center mt-2">{`Are you sure you want to purchase ${selectedCourse.name} for ${selectedCourse.price} EGP?`}</p>
                            )}

                            <div
                              className={`flex justify-around mt-4 ${
                                message && "hidden"
                              }`}
                            >
                              <button
                                onClick={(event) =>
                                  handleCourseBuy(event, selectedCourse)
                                }
                                className="bg-red-500 text-white px-4 py-2 rounded"
                              >
                                {purchaseCourse ? "Purchasing..." : "Yes"}
                              </button>
                              <button
                                onClick={() => {
                                  setConfirmPurchaseModalOpen(false);
                                  setSelectedCourse(null); // Reset the selected course
                                }}
                                className="bg-gray-500 text-white px-4 py-2 rounded"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    {mapErrors && (
                      <div
                        className={`fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50`}
                      >
                        <div
                          className={`${animationClass} relative flex flex-col ${
                            isDarkMode
                              ? "bg-gray-800 text-white"
                              : "bg-white text-black"
                          } p-6 rounded-lg shadow-lg w-80 h-auto`}
                        >
                          <div className="flex justify-center pb-4">
                            <MdError className="text-red-400" size={50} />
                          </div>
                          <h2 className="text-lg font-bold text-center">
                            {mapErrors}
                          </h2>
                          <p className="text-center mt-2">{`The course you are trying to buy is for ${selectedCourse.price} EGP, but your wallet balance is ${balance} EGP.`}</p>
                          <div
                            className={`flex justify-around mt-4 ${
                              message && "hidden"
                            }`}
                          >
                            <button
                              onClick={() => window.location.reload()}
                              className="bg-gray-500 text-white px-4 py-2 rounded"
                            >
                              Ok
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="flex items-center justify-end py-4 text-black">
            <label
              htmlFor="pageSize"
              className={`${
                isDarkMode ? "text-white" : "text-gray-700"
              } mr-2 text-sm`}
            >
              Courses per page:
            </label>
            <select
              id="pageSize"
              value={pageSize}
              onChange={handlePageSizeChange}
              className="px-4 py-2 border border-gray-300 rounded-lg"
            >
              {[20, 30, 50].map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>
          </div>

          {/* Pagination Controls (Bottom) */}
          <div className="flex justify-center pt-8">
            <div>
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => handlePageChange(index + 1)}
                  className={`px-4 py-2 ${
                    page === index + 1
                      ? ` ${
                          isDarkMode ? "bg-customRed" : "bg-customBlue"
                        } text-white`
                      : `${
                          isDarkMode ? "bg-gray-300 text-black" : "bg-gray-300"
                        } `
                  } rounded-[5px] mx-1 my-1`}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default StudentCoursePage;
