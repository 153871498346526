import React, { useEffect, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

import videosImg from "../../../assets/course_content_tab/videos.png";
import materialsImg from "../../../assets/course_content_tab/material.png";
import quizsImg from "../../../assets/course_content_tab/quizzes.png";
import assignmentsImg from "../../../assets/course_content_tab/Assignments.png";

import { DarkModeContext } from "../../../Context/DarkModeContext.jsx";

function CourseDetailPage() {
  const { isDarkMode } = useContext(DarkModeContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { courseId } = useParams();
  const { state } = location;
  const courseName = state?.name;

  // Pagination state
  const cardsPerPage = 4; // Number of cards per page
  const params = new URLSearchParams(location.search);
  const page = parseInt(params.get("page")) || 1;
  const pageSize = parseInt(params.get("pageSize")) || 20;
  const searchQuery = params.get("searchQuery") || "";
  const sortColumn = params.get("sortColumn") || "Name";
  const sortDirection = params.get("sortDirection") || "asc";

  // Cards data
  const cards = [
    { title: "Videos", image: videosImg, route: "videos" },
    { title: "Materials", image: materialsImg, route: "materials" },
    { title: "Quizzes", image: quizsImg, route: "quizzes" },
    { title: "Assignments", image: assignmentsImg, route: "assignments" },
  ];

  // Calculate current cards to display based on pagination
  const indexOfLastCard = cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = cards.slice(indexOfFirstCard, indexOfLastCard);

  // Effect to scroll to top on page change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  // Handlers for pagination
  const goToNextPage = () => {
    navigate(+1);
  };

  const goToPreviousPage = () => {
    const queryParams = new URLSearchParams({
      page,
      pageSize,
      searchQuery,
      sortColumn,
      sortDirection,
    }).toString();

    navigate(`/courses?${queryParams}`);
  };

  // Handler for card click
  const handleCardClick = (route) => {
    if (courseId) {
      navigate(
        `/courses/${courseId.replace(/\s+/g, "_").toLowerCase()}/${route}`,
        {
          state: { courseName },
        }
      );
    }
  };

  // Format path
  const pathSegments = location.pathname.slice(1).split("/");

  const formattedPath = pathSegments.join(" > ");

  return (
    <div
      className={`flex flex-col lg:flex-row lg:items-start ${
        isDarkMode ? "bg-[#292929] text-black" : "bg-white text-black"
      } p-4 lg:p-8 min-h-screen`}
    >
      <div
        className={`${
          isDarkMode ? "bg-[#1e1e1e] text-white" : "bg-white text-black"
        } p-4 lg:p-8 mt-[40px] lg:mt-0 rounded-lg shadow-xl flex-1`}
      >
        {/* PATH AND CONTROLS */}
        <div className="lg:flex w-full items-center mb-4 text-gray-700 text-sm capitalize">
          <div className="flex w-[58px] gap-1">
            <button
              onClick={goToPreviousPage}
              className="w-[24px] h-[20px] flex justify-center items-center bg-gray-300 rounded-[6px] disabled:opacity-50"
            >
              <IoIosArrowBack />
            </button>
            <button
              onClick={goToNextPage}
              className="w-[24px] h-[20px] flex justify-center items-center bg-gray-300 rounded-[6px] disabled:opacity-50"
            >
              <IoIosArrowForward />
            </button>
          </div>

          <div
            className={`${
              isDarkMode ? "text-white" : "text-black"
            } flex items-center text-sm capitalize p-2`}
          >
            <span>{formattedPath}</span>
          </div>
        </div>

        {/* Cards */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {currentCards.map((card, index) => (
            <div
              key={index}
              className="relative bg-white rounded-lg shadow-md overflow-hidden flex justify-center items-center cursor-pointer"
              onClick={() => handleCardClick(card.route)}
            >
              <img
                src={card.image}
                alt={card.title}
                className="w-full h-64 object-cover opacity-70"
              />
              <div className="absolute inset-0 flex justify-center items-center">
                <h3 className="text-white lg:text-[50px] md:text-[30px] text-xl font-bold">
                  {card.title}
                </h3>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CourseDetailPage;
